import * as React from "react";
const colors = new Map([["primary", "var(--primary)"], ["primary-dark", "var(--primary-dark)"], ["success", "var(--positive)"], ["danger", "var(--negative)"], ["white", "var(--accent-bright)"], ["mono", "var(--text)"]]);
const sizes = new Map([["small", "1rem"], ["medium", "1.5rem"], ["large", "2rem"], ["x-large", "3rem"]]);
const icBaselineCheckCircleOutline = ({
  title,
  titleId,
  ...props
}) => {
  const color = props.color === undefined ? "currentColor" : colors.get(props.color) || props.color;
  const style = {
    color,
    fill: color,
    height: props.size === undefined ? sizes.get("medium") : sizes.get(props.size) || props.size,
    width: props.size === undefined ? sizes.get("medium") : sizes.get(props.size) || props.size,
    verticalAlign: "middle"
  };
  props = {
    style,
    ...props
  };
  titleId = titleId || props.id;
  return <svg viewBox="0 0 24 24" width="1.2em" height="1.2em" aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5l8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8" /></svg>;
};
icBaselineCheckCircleOutline.displayName = "Icon";
export default icBaselineCheckCircleOutline;