import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { PatientDetailsContext } from "../patientDetailsContext";
import { POSTCODE_REGEX } from "../regex";
import BackLink from "../backLink";

type Props = {
    initialPostcode: string;
    onValidPostcode: (postcode: string) => void;
    onBack: () => void;
    initialError?: string;
};
function PostcodeSelection({ initialPostcode, onValidPostcode, onBack, initialError }: Props) {
    const { t } = useTranslation();

    const { patientDetails } = React.useContext(PatientDetailsContext);
    const [postcode, setPostcode] = React.useState(initialPostcode);
    const [error, setError] = React.useState(initialError ?? "");

    React.useEffect(() => {
        if (patientDetails?.postcode && !postcode) {
            setPostcode(patientDetails?.postcode);
        }
    }, [patientDetails]);

    const validatePostcode = () => {
        const canSearch = POSTCODE_REGEX.test(postcode);
        setError(canSearch ? "" : t("Search.InvalidPostcode"));
        if (canSearch) {
            onValidPostcode(postcode);
        }
    };
    const handleSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.blur();
        validatePostcode();
    };

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === "Enter") {
            validatePostcode();
        }
    };

    const updatePostcode = (e: React.ChangeEvent<HTMLInputElement>) => setPostcode(e.currentTarget.value);

    return (
        <>
            <BackLink onBack={onBack} />
            <div className="care-provider-search nhsuk-u-padding-top-3">
                <div className={`nhsuk-form-group${error ? " nhsuk-form-group--error" : ""} nhsuk-u-padding-top-4`}>
                    <fieldset className="nhsuk-fieldset">
                        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--m">
                            <h1 className="nhsuk-fieldset__heading">{t("Search.SearchForProvider")}</h1>
                        </legend>
                        <label className="nhsuk-label">{t("Search.EnterPostcode")}</label>

                        <span className="nhsuk-error-message">
                            <span className="field-validation-error nhsuk-u-visually-hidden">Error:</span> {error}
                        </span>
                    </fieldset>

                    <input
                        className="nhsuk-input nhsuk-input--width-20 "
                        data-testid="care-provider-search-box"
                        type="text"
                        value={postcode}
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={updatePostcode}
                        // eslint-disable-next-line react/jsx-no-bind
                        onKeyDown={handleKeyDown}
                    />
                </div>

                <div className="nhsuk-grid-row nhsuk-u-margin-top-9">
                    <div className="nhsuk-grid-column-full">
                        <button
                            data-testid="search-button"
                            className="nhsuk-button"
                            type="button"
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={handleSearch}
                        >
                            {t("Search.Search")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PostcodeSelection;
