import { useTranslation } from "@emisgroup/application-intl";
import React from "react";
import "./patientLedServiceError.scss";

type PatientLedServiceErrorProps = {
    serviceName: string;
};

const PatientLedServiceError = ({ serviceName }: PatientLedServiceErrorProps) => {
    const { t } = useTranslation();
    return (
        <div data-testid="patientLedServiceError" className="patient-led-service-error">
            <div className="service-name">{serviceName}</div>
            <div className="not-available">{t("View.ServiceNotAvailable")}</div>
            <div>{t("View.ContactPracticeInUsualWay")}</div>
            <div className="alternatively">Alternatively:</div>
            <ul className="alternatives">
                <li>Call 999 for emergency medical help</li>
                <li>
                    In England go to <a href="https://111.nhs.uk">111.nhs.uk</a> for advice about your symptoms
                </li>
                <li>
                    In Scotland go to <a href="https://nhs24.scot">NHS24.scot</a> for advice about your symptoms
                </li>
                <li>
                    In Isle of Man go to the&nbsp;
                    <a href="https://www.gov.im/categories/health-and-wellbeing/doctors/manx-emergency-doctor-service/">
                        Manx Emergency Doctor Service
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default PatientLedServiceError;
