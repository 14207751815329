import { ExceptionTelemetry } from "../telemetry";
import fetchWithRetry from "./fetchWithRetry";

const MAX_RETRIES = 3;
const RETRY_MILLISECONDS = 1000;

export enum GetResult {
    Success,
    Fail,
}

export type ServiceDetails = {
    title: string;
    id: string;
    description: string;
};

export type GetServicesResult =
    | { getResult: GetResult.Success; services: ServiceDetails[] }
    | { getResult: GetResult.Fail; status: number };

export default async function getServices(
    baseUrl: string,
    configApiUrl: string,
    trackException: (event: ExceptionTelemetry) => void,
    getRequestInit: () => Promise<RequestInit>,
): Promise<GetServicesResult> {
    try {
        const response = await fetchWithRetry(
            `${configApiUrl}/services?baseUrl=${baseUrl}`,
            await getRequestInit(),
            MAX_RETRIES,
            RETRY_MILLISECONDS,
        );

        if (response.ok) {
            return { getResult: GetResult.Success, services: (await response.json()).services };
        }

        if (response.status !== 404) {
            const responseText = await response.text();
            trackException({
                exception: new Error(
                    `Failed to get services: status:${response.status}, statusText:${response.statusText}, responseText:${responseText}`,
                ),
            });
        }

        return { getResult: GetResult.Fail, status: response.status };
    } catch (exception) {
        exception.message = `${exception.message} (get services)`;
        trackException({ exception });
        return { getResult: GetResult.Fail, status: 500 };
    }
}
