/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { Banner } from "@emisgroup/ui-banner";
import { Button } from "@emisgroup/ui-button";
import { useTranslation } from "@emisgroup/application-intl";
import Warning from "~icons/ic/baseline-warning-amber";

type PatientChangeWarningProps = {
    onDismiss: () => void;
};

function PatientChangeWarning({ onDismiss }: PatientChangeWarningProps) {
    const { t } = useTranslation();
    // NOTE: text must be wrapped in div to keep dismiss button aligned right
    return (
        <div>
            <Banner data-testid="patient-change-warning" color="warning">
                <Banner.Cap>
                    <Warning title="" />
                </Banner.Cap>
                <Banner.Content>
                    <div>{t("Patient.ChangeWarning")}</div>
                </Banner.Content>
                <Banner.Cap>
                    <Button
                        data-testid="patient-change-warning-dismiss-button"
                        variant="mono"
                        onClick={() => onDismiss()}
                    >
                        {t("Dismiss")}
                    </Button>
                </Banner.Cap>
            </Banner>
        </div>
    );
}

export default PatientChangeWarning;
