import * as React from "react";
import { TelemetryContext } from "../telemetry";
import { CareProviderRecord } from "./types";

type SelectedCareProviderContextType = {
    originPostcode: string;
    selectedCareProvider?: CareProviderRecord;
    onSelect: (careProvider?: CareProviderRecord) => void;
    onSearch: (originPostcode: string) => void;
};

export const SelectedCareProviderContext = React.createContext<SelectedCareProviderContextType>({
    originPostcode: "",
    onSelect: () => ({}),
    onSearch: () => ({}),
});

type SelectedCareProviderProviderProps = {
    initialCareProvider?: CareProviderRecord;
    children: React.ReactNode | React.ReactNode[];
};

export function SelectedCareProviderProvider({ initialCareProvider, children }: SelectedCareProviderProviderProps) {
    const [selectedCareProvider, setSelectedCareProvider] = React.useState<CareProviderRecord>(initialCareProvider);
    const [originPostcode, setOriginPostcode] = React.useState("");
    const { trackSelectProvider } = React.useContext(TelemetryContext);

    const handleSelect = (careProvider: CareProviderRecord) => {
        if (careProvider) trackSelectProvider({ id: careProvider.id });
        setSelectedCareProvider(careProvider);
    };

    const handleSearch = (postcode: string) => {
        setOriginPostcode(postcode);
    };
    return (
        <SelectedCareProviderContext.Provider
            value={{ originPostcode, selectedCareProvider, onSelect: handleSelect, onSearch: handleSearch }}
        >
            {children}
        </SelectedCareProviderContext.Provider>
    );
}
