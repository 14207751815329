import { createContext } from "react";
import { CareProviderRecord } from "./components/types";

type BaseSearchEvent = { term: string; timeMs?: number };
type SearchEvent = BaseSearchEvent & { results: CareProviderRecord[] };
type InvalidPostcodeEvent = BaseSearchEvent;
type TooManyOrganisationsMatchedEvent = BaseSearchEvent;
type ProviderEvent = { id: string };
type ReferralDeclinedEvent = { reason?: string };
type PatientServiceEvent = { patient: string; serviceId: string };
type PatientTimeoutEvent = {};
type ReferralCreatedEvent = PatientServiceEvent & { referralId: string };

export type ExceptionTelemetry = { exception: Error };
export type TelemetryContextType = {
    trackSearch: (event: SearchEvent) => void;
    trackInvalidPostcode: (event: InvalidPostcodeEvent) => void;
    trackTooManyOrganisationsMatched: (event: TooManyOrganisationsMatchedEvent) => void;
    trackSelectProvider: (event: ProviderEvent) => void;
    trackReferralDecline: (event: ReferralDeclinedEvent) => void;
    trackException: (event: ExceptionTelemetry) => void;
    trackStartAssessment: (event: PatientServiceEvent) => void;
    trackAssessmentComplete: (event: PatientServiceEvent) => void;
    trackAssessmentIneligible: (event: PatientServiceEvent) => void;
    trackAssessmentCancel: (event: PatientServiceEvent) => void;
    trackStartReferral: (event: PatientServiceEvent) => void;
    trackReferralCreated: (event: ReferralCreatedEvent) => void;
    trackBackToDashboard: (event: PatientServiceEvent) => void;
    trackPatientTimeout: (event: PatientTimeoutEvent) => void;
    flushLogs: () => void;
};

export const TelemetryContext = createContext<TelemetryContextType>({
    trackSearch: () => ({}),
    trackInvalidPostcode: () => ({}),
    trackTooManyOrganisationsMatched: () => ({}),
    trackSelectProvider: () => ({}),
    trackReferralDecline: () => ({}),
    trackException: () => ({}),
    trackStartAssessment: () => ({}),
    trackAssessmentComplete: () => ({}),
    trackAssessmentIneligible: () => ({}),
    trackAssessmentCancel: () => ({}),
    trackStartReferral: () => ({}),
    trackReferralCreated: () => ({}),
    trackBackToDashboard: () => ({}),
    trackPatientTimeout: () => ({}),
    flushLogs: () => ({}),
});

const SEARCH_EVENT_NAME = "Search"; // user searched for care providers or their GP practice
const INVALID_POSTCODE_EVENT_NAME = "InvalidPostcode"; // care provider search returned 400 for this postcode
const TOO_MANY_ORGANISATIONS_MATCHED_EVENT_NAME = "TooManyOrganisationsMatched"; // user searched for their GP practice and too many were matched
const SELECT_PROVIDER_EVENT_NAME = "SelectProvider"; // user searched for then selected a care provider
const REFERRAL_DECLINED_EVENT_NAME = "ReferralDeclined"; // "Decline" action in an assessment and user confirmed
const REFERRAL_CANCELLED_EVENT_NAME = "ReferralCancelled"; // "Discard" action in an assessment i.e. referral cancelled
const ASSESSMENT_START_EVENT_NAME = "AssessmentStart"; // Assessment template runner loaded
const ASSESSMENT_COMPLETE_EVENT_NAME = "AssessmentComplete"; // "Create" action in an assessment i.e. referral likely created
const ASSESSMENT_INELIGIBLE_EVENT_NAME = "AssessmentIneligible"; // "Stop" action in an assessment i.e. patient ineligible
const REFERRAL_START_EVENT_NAME = "ReferralStart"; // user reaches the select provider and referral details screen
const REFERRAL_CREATED_EVENT_NAME = "ReferralCreated"; // a referral was created in Referrals API
const BACK_TO_DASHBOARD_EVENT_NAME = "BackToDashboard"; // user quit back to the landing page
const PATIENT_TIMEOUT_EVENT_NAME = "PatientTimeout"; // user took too long to complete the patient details form

const BASE_SEARCH_TRACK = { term: "", timeMs: 0 };
const DEFAULT_SEARCH_TRACK = { ...BASE_SEARCH_TRACK, results: [] as CareProviderRecord[] };
const DEFAULT_INVALID_POSTCODE_TRACK = BASE_SEARCH_TRACK;
const DEFAULT_TOO_MANY_ORGANISATIONS_MATCHED = BASE_SEARCH_TRACK;
const DEFAULT_PROVIDER_TRACK = { id: "" };
const DEFAULT_REFERRAL_DECLINED_TRACK = { reason: "" };
const DEFAULT_PATIENT_SERVICE_TRACK = { patient: "", serviceId: "" };
const DEFAULT_REFERRAL_TRACK = { ...DEFAULT_PATIENT_SERVICE_TRACK, referralId: "" };

export function createValue(
    useTrackEvent: <DataType>(eventName: string, eventData: DataType) => React.Dispatch<DataType>,
    trackException: (exceptionTelemetry: ExceptionTelemetry) => void,
    flush: () => void,
): TelemetryContextType {
    return {
        trackSearch: useTrackEvent(SEARCH_EVENT_NAME, DEFAULT_SEARCH_TRACK),
        trackInvalidPostcode: useTrackEvent(INVALID_POSTCODE_EVENT_NAME, DEFAULT_INVALID_POSTCODE_TRACK),
        trackTooManyOrganisationsMatched: useTrackEvent(
            TOO_MANY_ORGANISATIONS_MATCHED_EVENT_NAME,
            DEFAULT_TOO_MANY_ORGANISATIONS_MATCHED,
        ),
        trackSelectProvider: useTrackEvent(SELECT_PROVIDER_EVENT_NAME, DEFAULT_PROVIDER_TRACK),
        trackReferralDecline: useTrackEvent(REFERRAL_DECLINED_EVENT_NAME, DEFAULT_REFERRAL_DECLINED_TRACK),
        trackException,
        trackStartAssessment: useTrackEvent(ASSESSMENT_START_EVENT_NAME, DEFAULT_PATIENT_SERVICE_TRACK),
        trackAssessmentComplete: useTrackEvent(ASSESSMENT_COMPLETE_EVENT_NAME, DEFAULT_PATIENT_SERVICE_TRACK),
        trackAssessmentIneligible: useTrackEvent(ASSESSMENT_INELIGIBLE_EVENT_NAME, DEFAULT_PATIENT_SERVICE_TRACK),
        trackAssessmentCancel: useTrackEvent(REFERRAL_CANCELLED_EVENT_NAME, DEFAULT_PATIENT_SERVICE_TRACK),
        trackStartReferral: useTrackEvent(REFERRAL_START_EVENT_NAME, DEFAULT_PATIENT_SERVICE_TRACK),
        trackReferralCreated: useTrackEvent(REFERRAL_CREATED_EVENT_NAME, DEFAULT_REFERRAL_TRACK),
        trackBackToDashboard: useTrackEvent(BACK_TO_DASHBOARD_EVENT_NAME, DEFAULT_PATIENT_SERVICE_TRACK),
        trackPatientTimeout: useTrackEvent(PATIENT_TIMEOUT_EVENT_NAME, {}),
        flushLogs: flush,
    };
}
