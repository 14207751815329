import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import PoweredByEmisXLogo from "./poweredByEmisXLogo";
import { COOKIE_POLICY_PATH, PRIVACY_NOTICE_PATH, ACCESSIBILITY_STATEMENT_PATH } from "../../constants";
import "./localServicesFooter.scss";

function LocalServicesFooter() {
    const { t } = useTranslation();
    const productFeedbackUrl = process.env.APP_PRODUCT_FEEDBACK_FORM_URL ?? "";

    return (
        <footer role="contentinfo">
            <div className="nhsuk-footer" id="nhsuk-footer">
                <div className="nhsuk-width-container">
                    <h2 className="nhsuk-u-visually-hidden">{t("SupportLinks.Title")}</h2>
                    <div className="nhsuk-footer__list">
                        <div className="nhsuk-footer__list-item">
                            <a
                                className="nhsuk-footer__list-item-link footer-link"
                                href={PRIVACY_NOTICE_PATH}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t("PrivacyNotice.Title")}
                            </a>
                        </div>

                        <div className="nhsuk-footer__list-item">
                            <a
                                className="nhsuk-footer__list-item-link footer-link"
                                href={COOKIE_POLICY_PATH}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t("CookiePolicy.Title")}
                            </a>
                        </div>

                        <div className="nhsuk-footer__list-item">
                            <a
                                className="nhsuk-footer__list-item-link footer-link"
                                href={ACCESSIBILITY_STATEMENT_PATH}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t("AccessibilityStatement.Title")}
                            </a>
                        </div>

                        {productFeedbackUrl && (
                            <div className="nhsuk-footer__list-item">
                                <a
                                    className="nhsuk-footer__list-item-link footer-link"
                                    href={productFeedbackUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t("ProductFeedback.Title")}
                                </a>
                            </div>
                        )}
                    </div>
                    <div className="nhsuk-footer__copyright">{PoweredByEmisXLogo()}</div>
                </div>
            </div>
        </footer>
    );
}

export default LocalServicesFooter;
