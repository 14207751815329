import * as React from "react";
import { Trans, useTranslation } from "@emisgroup/application-intl";
import RootContainer from "../RootContainer/rootContainer";
import { IElementProps } from "../../Types";
import "./cookiePolicy.scss";

type Props = IElementProps;

const CookiePolicy = ({ className }: Props) => {
    const { t } = useTranslation();
    return (
        <RootContainer centreContent={true} className={className}>
            <div role="main" className="cookie-policy">
                <div className="title">{t("CookiePolicy.Title")}</div>
                <div className="paragraph">{t("CookiePolicy.Description")}</div>
                <div className="sub-title">{t("CookiePolicy.CookieDefinitionTitle")}</div>
                <div className="paragraph">{t("CookiePolicy.CookieDefinition")}</div>
                <div className="paragraph">
                    <Trans i18nKey="CookiePolicy.WebsiteUsage" />
                </div>
                <div className="paragraph">{t("CookiePolicy.RequiredCookies")}</div>
                <div className="paragraph">{t("CookiePolicy.ThirdParties")}</div>
            </div>
        </RootContainer>
    );
};

export default CookiePolicy;
