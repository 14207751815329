import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";

const MIN_SEARCH_LENGTH = 4;

type Props = {
    onValidSearchSelection: (name: string) => void;
    initialError?: string;
    initialName?: string;
};
function SearchSelection({ onValidSearchSelection, initialError, initialName }: Props) {
    const { t } = useTranslation();

    const [name, setName] = React.useState(initialName ?? "");
    const [error, setError] = React.useState(initialError ?? "");

    const validateAndSearch = () => {
        const canSearch = name.replace(/\s/g, "").length >= MIN_SEARCH_LENGTH;
        setError(canSearch ? "" : t("Search.NameTooShort"));
        if (canSearch) {
            onValidSearchSelection(name);
        }
    };
    const handleSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.blur();
        validateAndSearch();
    };

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === "Enter") {
            validateAndSearch();
        }
    };

    const updateName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);

    return (
        <>
            <div className="organisation-search nhsuk-u-padding-top-3">
                <div className={`nhsuk-form-group${error ? " nhsuk-form-group--error" : ""} nhsuk-u-padding-top-4`}>
                    <fieldset className="nhsuk-fieldset">
                        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--m">
                            <h1 className="nhsuk-fieldset__heading">{t("Search.SearchForPractice")}</h1>
                        </legend>
                        <label className="nhsuk-label">{t("Search.EnterName")}</label>

                        <span className="nhsuk-error-message">
                            <span className="field-validation-error nhsuk-u-visually-hidden">Error:</span>
                            {error}
                        </span>
                    </fieldset>

                    <input
                        className="nhsuk-input nhsuk-input--width-20 "
                        data-testid="organisation-name"
                        type="text"
                        value={name}
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={updateName}
                        // eslint-disable-next-line react/jsx-no-bind
                        onKeyDown={handleKeyDown}
                    />
                </div>

                <div className="nhsuk-grid-row nhsuk-u-margin-top-9">
                    <div className="nhsuk-grid-column-full">
                        <button
                            data-testid="search-button"
                            className="nhsuk-button"
                            type="button"
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={handleSearch}
                        >
                            {t("Search.Search")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchSelection;
