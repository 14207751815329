import { ComponentContainer } from "@emisgroup/clint-templates-common";
import { ExceptionTelemetry } from "../telemetry";
import fetchWithRetry from "./fetchWithRetry";

const MAX_RETRIES = 3;
const RETRY_MILLISECONDS = 1000;

export default async function getServiceTemplate(
    serviceId: string,
    url: string,
    trackException: (event: ExceptionTelemetry) => void,
    getRequestInit: () => Promise<RequestInit>,
): Promise<ComponentContainer | null> {
    try {
        const response = await fetchWithRetry(
            `${url}?serviceId=${serviceId}`,
            await getRequestInit(),
            MAX_RETRIES,
            RETRY_MILLISECONDS,
        );

        if (response.ok) {
            return await response.json();
        }

        if (response.status === 404) {
            return null;
        }

        const responseText = await response.text();
        throw new Error(
            `Failed to get template: status:${response.status}, statusText:${response.statusText}, responseText:${responseText}`,
        );
    } catch (exception) {
        exception.message = `${exception.message} (get template)`;
        trackException({ exception });
        throw exception;
    }
}
