import { useTranslation } from "@emisgroup/application-intl";
import React from "react";
import "./notRegisteredAtPractice.scss";

const NotRegisteredAtPractice = () => {
    const { t } = useTranslation();
    return (
        <div className="not-registered-at-practice">
            <div className="header">{t("Practice.NotRegistered.Header")}</div>
            <div className="content">{t("Practice.NotRegistered.Content")}</div>
        </div>
    );
};

export default NotRegisteredAtPractice;
