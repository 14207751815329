import * as React from "react";
import { useSessionContext } from "@emisgroup/application-session-react";
import { useTokens } from "@emisgroup/patient-connect-common-react";
import { useLocation } from "react-router-dom";
import { DeclineType, postReferral, postDeclineReferral, postReferralError, ReferralErrorType } from "../api/referrals";
import getService from "../api/getService";
import { postClinicalRecord as fileClinicalRecord } from "../api/clinicalRecords";
import { careProviderSearch, organisationSearch } from "../api/queries";
import { PatientDetails } from "../components/types";
import { TelemetryContext } from "../telemetry";
import { EnvironmentContext } from "../environmentContext";
import { ServiceContext } from "../components/serviceContext";
import getServiceTemplate from "../api/getServiceTemplate";
import getServices from "../api/getServices";

type ApiContextType = {
    postReferral: (
        serviceId: string,
        serviceName: string,
        patientIdentifier: string,
        patientDetails: PatientDetails,
        careProviderId: string,
        careProviderName: string,
        assessmentTemplateResult: any,
        sendAssessmentToSubject: boolean,
        codeId: string,
    ) => Promise<any>;
    postDeclineReferral: (
        serviceId: string,
        patientIdentifier: string,
        patientDetails: PatientDetails,
        referralReason: string,
        declineType: DeclineType,
        assessmentTemplateResult: any,
        codeId: string,
    ) => Promise<any>;
    postReferralError: (referralId: string, referralErrorType: ReferralErrorType) => Promise<any>;
    getService: (serviceId: string) => Promise<any>;
    getServices: () => Promise<any>;
    getServiceTemplate: (serviceId: string) => Promise<any>;
    postClinicalRecord: (referralId: string, clinicalSystemToken: string, declineType?: DeclineType) => Promise<any>;
    careProviderSearch: (postCode: string, serviceId: string) => Promise<any>;
    organisationSearch: (name: string) => Promise<any>;
    isReady: boolean;
};

export const ApiContext = React.createContext<ApiContextType>({
    postReferral: () => Promise.resolve(),
    postDeclineReferral: () => Promise.resolve(),
    postReferralError: () => Promise.resolve(),
    getService: () => Promise.resolve(),
    getServices: () => Promise.resolve(),
    getServiceTemplate: () => Promise.resolve(),
    postClinicalRecord: () => Promise.resolve(),
    careProviderSearch: () => Promise.resolve(),
    organisationSearch: () => Promise.resolve(),
    isReady: false,
});

function withBody(init: RequestInit, params?: { body }): Promise<RequestInit> {
    return Promise.resolve({ ...init, method: "POST", body: JSON.stringify(params?.body) });
}

const getRequestInit =
    (initialisers: ((init: RequestInit, args: any) => Promise<RequestInit>)[]) => async (params?: any) => {
        let requestInit: RequestInit = {};
        for (const initialiser of initialisers) {
            requestInit = await initialiser(requestInit, params);
        }
        return { ...requestInit, headers: { ...requestInit.headers, "Content-Type": "application/json" } };
    };

type ApiProviderProps = {
    children: React.ReactNode | React.ReactNodeArray;
};

export function ApiProvider({ children }: ApiProviderProps) {
    const { trackException } = React.useContext(TelemetryContext);
    const { getPlatformToken, initialised: tokensInitialised } = useTokens();
    const { isPatientLed } = React.useContext(EnvironmentContext);
    const { orgId, organisation } = React.useContext(ServiceContext);
    const sessionContext = useSessionContext();
    const location = useLocation();

    function withOrg(init: RequestInit): Promise<RequestInit> {
        return Promise.resolve({
            ...init,
            headers: {
                ...init.headers,
                "organisation-id": `ods:${orgId}`,
                "organisation-name": organisation?.name,
            },
        });
    }

    const getNationalPracticeCode = () => {
        if (isPatientLed) {
            return null;
        }

        const nationalPracticeCode = sessionContext?.userClaims?.odsCode;

        if (!nationalPracticeCode) {
            throw new Error("No national practice code in session");
        }
        return nationalPracticeCode;
    };

    const getBaseUrl = () => {
        if (!isPatientLed) {
            return null;
        }

        const pathnameSplit = location.pathname.split("/");
        return pathnameSplit.length > 1 && pathnameSplit[1] !== "" ? `/${pathnameSplit[1]}` : null;
    };

    const withPlatformAuth = async (init: RequestInit) => {
        const platformToken = await getPlatformToken();
        return {
            ...init,
            headers: { ...init.headers, Authorization: `Bearer ${platformToken}` },
        };
    };

    const withAppMode = (init: RequestInit): Promise<RequestInit> => {
        return Promise.resolve({
            ...init,
            headers: {
                ...init.headers,
                "app-mode": isPatientLed ? "patient_led" : "practice_led",
            },
        });
    };

    const isReady = React.useMemo(() => isPatientLed || tokensInitialised, [tokensInitialised, isPatientLed]);

    const postClinicalRecord = (referralId: string, clinicalSystemToken: string, declineType?: DeclineType) =>
        fileClinicalRecord(referralId, clinicalSystemToken, getRequestInit([withBody, withPlatformAuth]), declineType);

    const endpoints = {
        referrals: `${process.env.APP_CORE_API_URL}/referrals`,
        config: `${process.env.APP_CORE_API_URL}/config`,
        query: process.env.APP_CORE_API_URL,
        template: `${process.env.APP_CORE_API_URL}/template`,
    };

    const referralsAuth = isPatientLed ? withOrg : withPlatformAuth;
    return (
        <ApiContext.Provider
            value={{
                postReferral: (...args) =>
                    postReferral(...args, endpoints.referrals, getRequestInit([withBody, referralsAuth, withAppMode])),
                postDeclineReferral: (...args) =>
                    postDeclineReferral(...args, endpoints.referrals, getRequestInit([withBody, referralsAuth])),
                postReferralError: (...args) =>
                    postReferralError(...args, endpoints.referrals, getRequestInit([withBody, referralsAuth])),
                getService: (...args) =>
                    getService(
                        getNationalPracticeCode(),
                        getBaseUrl(),
                        ...args,
                        endpoints.config,
                        trackException,
                        getRequestInit([withPlatformAuth]),
                    ),
                getServices: () =>
                    getServices(getBaseUrl(), endpoints.query, trackException, getRequestInit([withPlatformAuth])),
                getServiceTemplate: (...args) =>
                    getServiceTemplate(...args, endpoints.template, trackException, getRequestInit([withPlatformAuth])),
                careProviderSearch: (...args) =>
                    careProviderSearch(...args, endpoints.query, trackException, getRequestInit([])),
                organisationSearch: (...args) =>
                    organisationSearch(...args, endpoints.query, trackException, getRequestInit([])),
                postClinicalRecord,
                isReady,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
}
