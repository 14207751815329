/* eslint-disable no-console */
import React from "react";
import { Routes as RoutedComponent } from "../routes/Index";

/**
 * Responsible for rendering the component as per route path
 */
export const Layout: React.FC = () => {
    return (
        <div style={{ height: "100%" }}>
            <RoutedComponent />
        </div>
    );
};

export default Layout;
