import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { IElementProps } from "../../Types";
import RootContainer from "../RootContainer/rootContainer";
import "./privacyNotice.scss";

type Props = IElementProps;

function PrivacyNotice({ className }: Props) {
    const { t } = useTranslation();
    return (
        <RootContainer centreContent={true} className={className}>
            <div role="main" className="privacy-notice">
                <div className="title">{t("PrivacyNotice.Title")}</div>
                <div className="paragraph">{t("PrivacyNotice.AnswerSubmission")}</div>
                <div className="paragraph">{t("PrivacyNotice.HistoryRecommendation")}</div>
                <div className="paragraph">{t("PrivacyNotice.HistoryRecommendationReason")}</div>
                <div className="paragraph">{t("PrivacyNotice.GPPracticePrivacy")}</div>
            </div>
        </RootContainer>
    );
}

export default PrivacyNotice;
