import { InfoError } from "@emisgroup/icons-react";
import React from "react";

import "./error.scss";

type ErrorProps = {
    title: string;
    detail: string;
};

const Error = ({ title, detail }: ErrorProps): JSX.Element => {
    return (
        <div className="error-container" data-testid="loading-error">
            <InfoError title={title} />
            <div className="error-title">{title}</div>
            <div className="error-text">{detail}</div>
        </div>
    );
};

export default Error;
