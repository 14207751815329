import * as React from "react";

const MIN_MILLISECONDS_TO_WAIT = 2000;
const MAX_MILLISECONDS_TO_WAIT = 10000;

const useTimers = (
    loading: boolean,
    delay: number,
    timeout: number,
): {
    showSpinner: boolean;
    showError: boolean;
} => {
    const [showSpinner, setShowSpinner] = React.useState(delay <= 0);
    const [showError, setShowError] = React.useState(false);
    React.useEffect(() => {
        let spinnerTimer: NodeJS.Timeout | null = null;
        let errorTimer: NodeJS.Timeout | null = null;
        const clearTimers = () => {
            if (errorTimer) {
                clearTimeout(errorTimer);
            }
            if (spinnerTimer) {
                clearTimeout(spinnerTimer);
            }
        };

        if (loading) {
            setShowSpinner(delay <= 0);
            setShowError(false);
            if (delay > 0) {
                spinnerTimer = setTimeout(() => setShowSpinner(true), delay);
            }
            errorTimer = setTimeout(() => setShowError(true), timeout);
        } else {
            setShowSpinner(false);
            clearTimers();
        }

        return () => {
            clearTimers();
        };
    }, [loading]);
    return { showSpinner, showError };
};

type IndexProps<T> = {
    loading: boolean;
    error: boolean;
    term?: string;
    data?: T;
    loadingComponent: React.FC;
    errorComponent: React.FC;
    dataComponent: React.FC<{ term?: string; data: T }>;
};

function Index<T>({
    loading,
    error,
    term,
    data,
    loadingComponent: Loading,
    errorComponent: Error,
    dataComponent: Data,
}: Readonly<IndexProps<T>>) {
    const { showSpinner, showError } = useTimers(loading, MIN_MILLISECONDS_TO_WAIT, MAX_MILLISECONDS_TO_WAIT);
    switch (true) {
        case error:
        case showError:
            return <Error />;
        case showSpinner:
            return <Loading />;
        case Boolean(data):
            return <Data term={term} data={data} />;
        default:
            return null;
    }
}

export default Index;
