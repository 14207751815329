import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-button";
import { IElementProps } from "../../Types";
import RootContainer from "../RootContainer/rootContainer";
import useBackButton from "../useBackButton";
import { getPatientActivityTimeout } from "./usePatientActivityTimeout";
import "./PatientTimeout.scss";

type Props = IElementProps;

function PatientTimeout({ className }: Props) {
    const { t } = useTranslation();
    const { buttonProps } = useBackButton();
    const timeout = getPatientActivityTimeout();
    return (
        <RootContainer centreContent={true} className={className}>
            <div role="main" className="patient-timeout">
                <div className="title">{t("PatientTimeout.Title")}</div>
                <div className="paragraph">
                    {t("PatientTimeout.Explanation", { timeout, plural: timeout === 1 ? "" : "s" })}
                </div>
                <div className="paragraph">{t("PatientTimeout.NextStep")}</div>
                <Button className="paragraph" {...buttonProps} variant="filled">
                    {t("PatientTimeout.StartAgain")}
                </Button>
            </div>
        </RootContainer>
    );
}

export default PatientTimeout;
