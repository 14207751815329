/* eslint-disable react/jsx-no-bind */
import { useTranslation } from "@emisgroup/application-intl";
import { ProgressSpinnerSmall } from "@emisgroup/ui-kit-react";
import * as React from "react";
import BackLink from "../backLink";
import Query from "../Query";
import ResultsView from "./resultsView";
import Error from "../Query/error";
import useOrganisationSearch from "./useOrganisationSearch";
import { useNavigate } from "react-router-dom";

function QueryError() {
    const { t } = useTranslation();
    return <Error title={t("Search.ErrorTitle")} text={t("Search.ErrorText")} />;
}

function QueryLoading() {
    const { t } = useTranslation();
    return (
        <ProgressSpinnerSmall data-testid="search-spinner" className="search-spinner" text={t("Search.Searching")} />
    );
}

export type ResultsProps = {
    name: string;
    onInvalid?: () => void;
};

function Results({ name, onInvalid }: ResultsProps) {
    const { loading, data, error, tooManyOrganisationsMatched } = useOrganisationSearch(name);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (onInvalid && tooManyOrganisationsMatched) onInvalid();
    }, [onInvalid, tooManyOrganisationsMatched]);

    const handleOnBack = React.useCallback(() => {
        navigate("/");
    }, [navigate]);

    return (
        <>
            <BackLink onBack={handleOnBack} />
            <div className="results-panel">
                <Query
                    loading={loading ?? false}
                    data={data}
                    error={error ?? false}
                    term={name}
                    errorComponent={QueryError}
                    loadingComponent={QueryLoading}
                    dataComponent={ResultsView}
                />
            </div>
        </>
    );
}

export default Results;
