import { OrganisationAddress } from "../api/getService";
import { ExceptionTelemetry } from "../telemetry";

export const buildAddressSummary = (address: OrganisationAddress) =>
    address
        ? [
              address.addressLine1,
              address.addressLine2,
              address.addressLine3,
              address.addressLine4,
              address.addressCity,
              address.addressRegion,
              address.postalCode,
          ]
              .filter(Boolean)
              .join(", ")
        : "";

export const tryTrack = async (
    tryFn: () => Promise<void>,
    trackFn: (event: ExceptionTelemetry) => void,
): Promise<boolean> => {
    try {
        await tryFn();
        return true;
    } catch (error) {
        trackFn({ exception: error });
        return false;
    }
};
