import React from "react";
import { GetResult } from "../../api/getService";
import { QueryResult } from "../../api/queries";
import { ApiContext } from "../../context/api";
import { TelemetryContext } from "../../telemetry";

export type UseOrganisationStatus = {
    data?: any;
    loading?: boolean;
    tooManyOrganisationsMatched?: boolean;
    error?: boolean;
};

function useOrganisationSearch(name: string): UseOrganisationStatus {
    const [status, setStatus] = React.useState<UseOrganisationStatus>({});
    const { organisationSearch } = React.useContext(ApiContext);
    const { trackException, trackSearch, trackTooManyOrganisationsMatched } = React.useContext(TelemetryContext);

    React.useEffect(() => {
        const doSearch = async () => {
            setStatus({ loading: true });
            try {
                const result: QueryResult = await organisationSearch(name);
                if (result.getResult === GetResult.Success) {
                    setStatus({ data: result.data });
                    trackSearch({ term: `name=${name}`, results: result.data });
                } else if (result.status === 400) {
                    setStatus({ tooManyOrganisationsMatched: true });
                    trackTooManyOrganisationsMatched({ term: `name=${name}` });
                } else {
                    setStatus({ error: true });
                    trackSearch({ term: `name=${name}`, results: [] });
                }
            } catch (e) {
                setStatus({ error: true });
                trackException({ exception: e });
            }
        };

        doSearch();
    }, [name]);

    return status;
}

export default useOrganisationSearch;
