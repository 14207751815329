import { useTranslation } from "@emisgroup/application-intl";
import * as React from "react";
import PostcodeSelection from "./postcodeSelection";
import Results from "./results";
import "../searches.scss";

enum SearchStates {
    SelectPostcode,
    ShowResults,
}

type CareProviderSearchProps = {
    postcode: string;
    setPostcode: (postcode: string) => void;
    onBack: () => void;
};

function Index(props: CareProviderSearchProps) {
    const { t } = useTranslation();
    const { postcode, setPostcode, onBack } = props;
    const [error, setError] = React.useState<string | undefined>();
    const [searchState, setSearchState] = React.useState<SearchStates>(
        postcode ? SearchStates.ShowResults : SearchStates.SelectPostcode,
    );

    const handleValidPostcode = (enteredPostcode: string) => {
        setPostcode(enteredPostcode);
        setError(undefined);
        setSearchState(SearchStates.ShowResults);
    };

    const handleResultsOnBack = () => {
        setSearchState(SearchStates.SelectPostcode);
    };

    const handleInvalidSearch = () => {
        setError(t("Search.InvalidPostcode"));
        setSearchState(SearchStates.SelectPostcode);
    };

    return searchState === SearchStates.SelectPostcode ? (
        <PostcodeSelection
            initialPostcode={postcode}
            initialError={error}
            onBack={onBack}
            // eslint-disable-next-line react/jsx-no-bind
            onValidPostcode={handleValidPostcode}
        />
    ) : (
        // eslint-disable-next-line react/jsx-no-bind
        <Results term={postcode} onBack={handleResultsOnBack} onInvalid={handleInvalidSearch} />
    );
}

export default Index;
