import { openApiDataSource } from "@emisgroup/clint-comms-contact-details";
import { PatientDetails as KnownPatientDetails } from "@emisgroup/clint-comms-types";
import { Gender, PatientDetails } from "../components/types";

function toPatientDetails(knownPatientDetails: KnownPatientDetails): PatientDetails {
    return {
        firstName: knownPatientDetails.firstName,
        lastName: knownPatientDetails.lastName,
        mobilePhoneNumber: knownPatientDetails.mobilePhoneNumber,
        homePhoneNumber: knownPatientDetails.homePhoneNumber,
        email: knownPatientDetails.emailAddress,
        postcode: knownPatientDetails.postcode,
        addressLine1: knownPatientDetails.addressFirstLine,
        gender: Gender[knownPatientDetails.gender],
        nhsNumber: knownPatientDetails.nhsNumber,
        dateOfBirth: new Date(knownPatientDetails.dateOfBirth),
        referralReason: undefined,
        notes: undefined,
        isThirdPartyContact: false,
        contactFirstName: undefined,
        contactLastName: undefined,
    };
}

// eslint-disable-next-line import/prefer-default-export
export async function getPatientDetails(
    openApiUrl: string,
    accessToken: string,
    patientGuid: string,
): Promise<PatientDetails> {
    const openApi = openApiDataSource(openApiUrl, accessToken);
    const knownPatientDetails = await openApi.getPatientDetails(patientGuid);
    return toPatientDetails(knownPatientDetails);
}
