import { ComponentContainer } from "@emisgroup/clint-templates-common";
import { ExceptionTelemetry } from "../telemetry";
import fetchWithRetry from "./fetchWithRetry";

const MAX_RETRIES = 3;
const RETRY_MILLISECONDS = 1000;

export enum GetResult {
    Success,
    Fail,
}

export type OrganisationAddress = {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
    addressCity?: string;
    addressRegion?: string;
    postalCode?: string;
};

export type Organisation = {
    name: string;
    address: OrganisationAddress;
};

export type Service = {
    orgId?: string;
    assessmentTemplateDefinition?: ComponentContainer;
    mandatoryAssessment?: boolean;
    referralCreatedCodeId?: string;
    referralDeclinedCodeId?: string;
    referralErrorMessage?: string;
    enablePatientLed?: boolean;
    name?: string;
    organisation?: Organisation;
    referredReturnUrl?: string;
};

type GetServiceResult =
    | { getResult: GetResult.Success; service?: Service }
    | { getResult: GetResult.Fail; status: number };

export default async function getService(
    nationalPracticeCode: string | null,
    baseUrl: string | null,
    serviceId: string,
    configApiUrl: string,
    trackException: (event: ExceptionTelemetry) => void,
    getRequestInit: () => Promise<RequestInit>,
): Promise<GetServiceResult> {
    const orgIdentifierQuery = nationalPracticeCode ? `orgId=${nationalPracticeCode}` : `baseUrl=${baseUrl}`;
    try {
        const response = await fetchWithRetry(
            `${configApiUrl}?${orgIdentifierQuery}&serviceId=${serviceId}`,
            await getRequestInit(),
            MAX_RETRIES,
            RETRY_MILLISECONDS,
        );

        if (response.ok) {
            return { getResult: GetResult.Success, service: await response.json() };
        }

        if (response.status !== 404) {
            const responseText = await response.text();
            trackException({
                exception: new Error(
                    `Failed to get service: status:${response.status}, statusText:${response.statusText}, responseText:${responseText}`,
                ),
            });
        }

        return { getResult: GetResult.Fail, status: response.status };
    } catch (exception) {
        exception.message = `${exception.message} (get service)`;
        trackException({ exception });
        return { getResult: GetResult.Fail, status: 500 };
    }
}
