import { SessionItems } from "@emisgroup/application-session-management";
import { useSessionContext } from "@emisgroup/application-session-react";

const usePatientGuid = () => {
    const sessionContext = useSessionContext();
    const sessionPatient = sessionContext.GetItem(SessionItems.Patient);
    const patient = JSON.parse(sessionPatient as string);

    return patient?.PatientGuid;
};

export default usePatientGuid;
