/* eslint-disable import/prefer-default-export */
import { checkResponse } from "@emisgroup/patient-connect-common-react";
import fetchWithRetry from "./fetchWithRetry";
import { DeclineType } from "./referrals";

const clinicalSystemUrl = process.env.APP_CORE_API_URL ?? "";
const numberOfRetries = Number(process.env.APP_CORE_API_RETRIES ?? 3);
const retryWaitMilliseconds = Number(process.env.APP_CORE_API_RETRY_WAIT_MS ?? 500);

export const postClinicalRecord = async (
    referralId: string,
    clinicalSystemToken: string,
    getRequestInit: (params: any) => Promise<RequestInit>,
    declineType?: DeclineType,
) => {
    const body = {
        subject: referralId,
        openApiToken: clinicalSystemToken,
        eventType: declineType ? "NotReferred" : "Referred",
    };

    let response;
    try {
        response = await fetchWithRetry(
            `${clinicalSystemUrl}/consultations/referral`,
            await getRequestInit({ body }),
            numberOfRetries,
            retryWaitMilliseconds,
        );
    } catch (exception) {
        exception.message = `${exception.message} (post clinical record)`;
        throw exception;
    }

    await checkResponse(response, "Failed to post clinical record");
};
