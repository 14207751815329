import React from "react";

const PoweredByEmisXLogo = () => {
    return (
        <svg width="105" height="44" viewBox="0 0 105 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_765_10880)">
                <path
                    d="M100.778 21.8536C100.889 21.7098 100.961 21.5407 100.988 21.3621C101.015 21.1835 100.996 21.001 100.933 20.8315L100.39 22.3346L100.778 21.8536Z"
                    fill="#333333"
                />
                <path
                    d="M100.39 22.3347L100.933 20.8317C100.92 20.7979 100.906 20.7649 100.89 20.7327C100.801 20.5536 100.662 20.4028 100.49 20.2976C100.319 20.1923 100.12 20.1369 99.9174 20.1377H99.5001L100.383 22.3437L100.39 22.3347Z"
                    fill="#333333"
                />
                <path
                    d="M99.8241 37.8291L99.0806 38.2403L100.867 39.1486C100.841 39.1004 100.81 39.0542 100.776 39.0106L99.8241 37.8291Z"
                    fill="#333333"
                />
                <path
                    d="M100.603 40.4882C100.724 40.3913 100.821 40.27 100.89 40.1327C101.046 39.8209 101.035 39.4503 100.868 39.149L99.0806 38.2407L100.603 40.4882Z"
                    fill="#333333"
                />
                <path d="M99.8241 37.8293L99.074 36.8984L99.0807 38.2406L99.8241 37.8293Z" fill="#333333" />
                <path
                    d="M97.3219 39.8448L99.5624 40.7277H99.9169C100.167 40.7285 100.409 40.6438 100.603 40.4882L99.0806 38.2407L97.3219 39.8448Z"
                    fill="#333333"
                />
                <path d="M99.3364 20.1377H97.0471L95.9573 24.4754L99.3364 20.1377Z" fill="#333333" />
                <path
                    d="M100.374 22.354L100.382 22.3437L99.5 20.1377H99.3364L95.9573 24.4754L100.374 22.354Z"
                    fill="#333333"
                />
                <path d="M97.237 26.2464L100.374 22.354L95.9573 24.4753L97.237 26.2464Z" fill="#333333" />
                <path d="M95.7333 28.1119L97.237 26.2462L95.9573 24.4751L95.7333 28.1119Z" fill="#333333" />
                <path
                    d="M95.9756 40.4817C96.1703 40.64 96.4147 40.727 96.6673 40.7277H99.5625L97.322 39.8447L95.9756 40.4817Z"
                    fill="#333333"
                />
                <path
                    d="M93.7437 35.9648L93.7535 37.7838L95.8059 40.3084C95.8556 40.3722 95.9125 40.4302 95.9755 40.4813L97.3218 39.8442L93.7437 35.9648Z"
                    fill="#333333"
                />
                <path d="M96.6451 33.8848L94.958 31.7915L93.7437 35.9651L96.6451 33.8848Z" fill="#333333" />
                <path
                    d="M99.0806 38.2407L99.0739 36.8985L96.6451 33.8848L93.7437 35.9651L99.0806 38.2407Z"
                    fill="#333333"
                />
                <path d="M97.3218 39.8445L99.0806 38.2404L93.7437 35.9648L97.3218 39.8445Z" fill="#333333" />
                <path
                    d="M97.0471 20.1377H96.6672C96.501 20.138 96.3371 20.1758 96.188 20.2482C96.0389 20.3205 95.9087 20.4255 95.8073 20.5551L93.7554 23.0789L95.9575 24.4754L97.0471 20.1377Z"
                    fill="#333333"
                />
                <path
                    d="M93.7436 35.9652L94.958 31.7915L93.863 30.4327L94.0315 30.2236L92.3486 31.0783L93.7436 35.9652Z"
                    fill="#333333"
                />
                <path
                    d="M94.0316 30.2235L95.1375 28.8512L91.8771 25.3896L91.4423 25.9245L92.3486 31.0781L94.0316 30.2235Z"
                    fill="#333333"
                />
                <path
                    d="M95.7333 28.1118L95.9572 24.4751L92.4868 24.6393L91.8771 25.3894L95.1375 28.851L95.7333 28.1118Z"
                    fill="#333333"
                />
                <path d="M93.7554 23.0791L92.4868 24.6396L95.9573 24.4754L93.7554 23.0791Z" fill="#333333" />
                <path d="M93.0933 36.9714L93.7536 37.7838L93.7437 35.9648L93.0933 36.9714Z" fill="#333333" />
                <path
                    d="M92.3488 31.0781L90.9414 34.3244L92.1119 35.7643L93.7437 35.965L92.3488 31.0781Z"
                    fill="#333333"
                />
                <path d="M92.1118 35.7642L93.0933 36.9715L93.7437 35.9649L92.1118 35.7642Z" fill="#333333" />
                <path
                    d="M92.3487 31.0779L91.4423 25.9243L90.7958 26.7197L90.2497 26.0478L88.4976 28.4667L92.3487 31.0779Z"
                    fill="#333333"
                />
                <path d="M90.2497 26.048L88.7163 24.1616L88.4976 28.4668L90.2497 26.048Z" fill="#333333" />
                <path d="M87.9556 37.6393L89.021 36.3286L87.4666 37.196L87.9556 37.6393Z" fill="#333333" />
                <path
                    d="M86.8652 31.5028L87.4665 37.1961L89.021 36.3287L90.0485 35.0647L87.1641 31.1318L86.8652 31.5028Z"
                    fill="#333333"
                />
                <path
                    d="M87.5439 30.6606L87.1642 31.1318L90.0486 35.0647L90.7959 34.1454L90.9414 34.3245L92.3487 31.0782L87.5439 30.6606Z"
                    fill="#333333"
                />
                <path
                    d="M88.4975 28.4668L87.3158 29.9205L87.728 30.432L87.5438 30.6605L92.3486 31.078L88.4975 28.4668Z"
                    fill="#333333"
                />
                <path
                    d="M85.7675 40.3313C85.7733 40.3239 85.779 40.317 85.7847 40.3098L87.9556 37.6391L87.4666 37.1958L85.7675 40.3313Z"
                    fill="#333333"
                />
                <path
                    d="M88.7164 24.1616L88.4858 23.8779L84.6865 26.6578L85.3473 27.4778L88.4976 28.4667L88.7164 24.1616Z"
                    fill="#333333"
                />
                <path d="M85.3473 27.478L87.3158 29.9207L88.4975 28.467L85.3473 27.478Z" fill="#333333" />
                <path d="M86.8653 31.5029L84.8607 33.9904L87.4666 37.1962L86.8653 31.5029Z" fill="#333333" />
                <path
                    d="M87.9606 23.2319L85.7856 20.5565C85.6871 20.4304 85.5613 20.3274 85.4173 20.255C85.2733 20.1827 85.1147 20.1428 84.9531 20.1382L83.5548 20.6328L87.9606 23.2319Z"
                    fill="#333333"
                />
                <path
                    d="M87.9606 23.2319L83.5548 20.6328L83.9914 25.7953L84.6864 26.6577L88.4857 23.8779L87.9606 23.2319Z"
                    fill="#333333"
                />
                <path
                    d="M83.2212 40.2174L83.935 40.7273H84.9244C85.086 40.7269 85.2454 40.6911 85.3912 40.6226C85.537 40.5542 85.6655 40.4546 85.7674 40.3313L87.4666 37.1958L83.2212 40.2174Z"
                    fill="#333333"
                />
                <path
                    d="M87.4666 37.196L84.8607 33.9902L83.4891 35.6922L83.2212 40.2177L87.4666 37.196Z"
                    fill="#333333"
                />
                <path
                    d="M84.9531 20.1381C84.9435 20.1381 84.934 20.1377 84.9244 20.1377H83.2689L83.5547 20.633L84.9531 20.1381Z"
                    fill="#333333"
                />
                <path d="M82.1226 23.4763L83.9914 25.7953L83.5549 20.6328L82.1226 23.4763Z" fill="#333333" />
                <path d="M83.489 35.6924L82.0349 37.4968L83.2212 40.2179L83.489 35.6924Z" fill="#333333" />
                <path d="M82.0266 40.7276H83.9351L83.2212 40.2178L82.0266 40.7276Z" fill="#333333" />
                <path
                    d="M83.269 20.1377H81.6742C81.4717 20.1369 81.2731 20.1923 81.1011 20.2976C80.9291 20.4028 80.7908 20.5536 80.702 20.7327C80.67 20.7968 80.6447 20.8639 80.6266 20.9331L83.5548 20.6329L83.269 20.1377Z"
                    fill="#333333"
                />
                <path
                    d="M80.6266 20.9331C80.5852 21.0906 80.5807 21.2554 80.6133 21.4149C80.646 21.5745 80.715 21.7246 80.8152 21.8541L82.1223 23.4764L83.5548 20.6328L80.6266 20.9331Z"
                    fill="#333333"
                />
                <path d="M82.0349 37.4966L81.0552 38.7124L83.2212 40.2176L82.0349 37.4966Z" fill="#333333" />
                <path
                    d="M81.0552 38.7124L80.8138 39.0115C80.6917 39.1699 80.6166 39.3585 80.5968 39.5564C80.577 39.7542 80.6134 39.9536 80.7019 40.1324C80.7907 40.3114 80.929 40.4622 81.101 40.5675C81.2729 40.6727 81.4716 40.7281 81.6741 40.7274H82.0263L83.221 40.2175L81.0552 38.7124Z"
                    fill="#333333"
                />
                <path
                    d="M81.0159 32.5599H77.079C76.905 32.5599 76.738 32.4918 76.615 32.3707C76.4919 32.2496 76.4227 32.0854 76.4227 31.9141V28.9627C76.4227 28.7914 76.4919 28.6271 76.615 28.506C76.738 28.3849 76.905 28.3169 77.079 28.3169H81.0198C81.1288 28.3169 81.2361 28.3436 81.332 28.3946C81.4279 28.4456 81.5093 28.5193 81.569 28.6091L82.8213 29.9917C82.9277 30.1091 82.9868 30.2608 82.9876 30.4181C82.9885 30.5755 82.9309 30.7277 82.8257 30.8462L81.5684 32.2624C81.509 32.3536 81.4273 32.4286 81.3307 32.4806C81.2342 32.5326 81.1259 32.5598 81.0159 32.5599V32.5599Z"
                    fill="#333333"
                />
                <path
                    d="M67.4762 28.5525C63.6198 27.6457 62.5744 27.197 62.5744 25.766V25.7081C62.5744 24.567 63.7393 23.8292 65.5414 23.8292C66.9596 23.8292 68.444 24.2436 70.0779 25.0955C70.8277 25.4859 71.7511 25.3023 72.2263 24.6674L72.9168 23.7485C73.1653 23.4173 73.2577 23.0012 73.1714 22.6083C73.0842 22.2186 72.8361 21.8934 72.474 21.6924C70.4493 20.5688 68.138 20 65.6051 20C61.032 20 57.8383 22.5163 57.8383 26.1187V26.1769C57.8383 30.3387 60.8925 31.376 65.2066 32.3874C68.8032 33.2342 69.7884 33.8142 69.7884 35.0864V35.1458C69.7884 36.4138 68.5477 37.1705 66.4709 37.1705C64.5588 37.1705 62.7558 36.6265 60.957 35.5053C60.6786 35.333 60.3565 35.2417 60.0276 35.2419C59.5742 35.2419 59.1322 35.4169 58.827 35.7532L58.0388 36.618C57.7511 36.9346 57.6264 37.3327 57.6857 37.7393C57.7484 38.1639 58.0109 38.5484 58.4067 38.7928C60.7469 40.2367 63.503 41.0002 66.3741 41.0002C71.4023 41.0002 74.5254 38.5878 74.5254 34.7049V34.6465C74.5254 30.9884 71.7075 29.5651 67.4762 28.5525"
                    fill="#333333"
                />
                <path
                    d="M53.0043 20.1377H51.0826C50.2814 20.1377 49.6292 20.7777 49.6292 21.5635V39.3026C49.6292 40.0894 50.2814 40.7285 51.0826 40.7285H53.0043C53.8056 40.7285 54.4578 40.0893 54.4578 39.3026V21.5635C54.4578 20.7777 53.8056 20.1377 53.0043 20.1377"
                    fill="#333333"
                />
                <path
                    d="M44.2938 20.1378H41.0661C40.5839 20.1378 40.2247 20.342 39.9971 20.6972C39.8481 20.9281 35.234 28.2023 35.2104 28.2394C35.1869 28.2023 30.5728 20.9281 30.4246 20.6975C30.1961 20.3423 29.8369 20.1381 29.3548 20.1381H26.1165C25.3144 20.1381 24.6631 20.7773 24.6631 21.5633V39.3035C24.6631 40.0894 25.3144 40.7287 26.1165 40.7287H27.7601C28.5614 40.7287 29.2135 40.0895 29.2135 39.3035L29.2248 26.7746C29.2248 26.7746 34.3331 34.3646 34.5547 34.7035L34.5722 34.7309C34.764 35.0253 34.9846 35.1608 35.2104 35.1479C35.4371 35.1608 35.6569 35.0253 35.8486 34.7309C35.8547 34.7215 35.8608 34.7121 35.867 34.7035C36.0875 34.3646 41.1969 26.7738 41.1969 26.7738V39.3027C41.1969 40.0886 41.849 40.7279 42.6503 40.7279H44.2938C45.096 40.7279 45.7473 40.0887 45.7473 39.3027V21.5629C45.7473 20.777 45.096 20.1377 44.2938 20.1377"
                    fill="#333333"
                />
                <path
                    d="M20.3733 20.1377L10.3895 20.142C6.7465 20.142 4 22.8624 4 26.4708V34.3824C4 36.2622 4.82226 38.0303 6.25473 39.2374L6.45698 39.3996C7.57216 40.2557 8.95763 40.7104 10.4581 40.7104L20.3716 40.7276C21.1737 40.7276 21.8259 40.0859 21.8259 39.2966V37.5893C21.8259 36.8001 21.1737 36.1583 20.3716 36.1583H10.4529C9.60541 36.1583 8.91576 35.4806 8.91576 34.6459V32.8871C8.91579 32.8525 8.92276 32.8182 8.93628 32.7862C8.9498 32.7542 8.9696 32.7251 8.99454 32.7007C9.01948 32.6762 9.04909 32.6568 9.08165 32.6436C9.11421 32.6304 9.1491 32.6237 9.18432 32.6238H18.1613C18.9216 32.586 19.5284 31.9589 19.5284 31.1971V29.6347C19.5284 28.8454 18.8754 28.2037 18.0742 28.2037H9.18432C9.14903 28.2038 9.11408 28.197 9.08147 28.1837C9.04887 28.1705 9.01924 28.151 8.99429 28.1265C8.96934 28.1019 8.94956 28.0728 8.93608 28.0407C8.92261 28.0086 8.9157 27.9742 8.91576 27.9395V26.1817C8.91576 25.3469 9.60541 24.6684 10.4529 24.6684H20.3724C21.1746 24.6684 21.8268 24.0266 21.8268 23.2373L21.8277 21.5687C21.8277 20.7794 21.1754 20.1377 20.3733 20.1377"
                    fill="#333333"
                />
            </g>
            <path
                d="M6.44727 9.21191H4.54785V8.44531H6.44727C6.8151 8.44531 7.11296 8.38672 7.34082 8.26953C7.56868 8.15234 7.7347 7.98958 7.83887 7.78125C7.94629 7.57292 8 7.33529 8 7.06836C8 6.82422 7.94629 6.59473 7.83887 6.37988C7.7347 6.16504 7.56868 5.99251 7.34082 5.8623C7.11296 5.72884 6.8151 5.66211 6.44727 5.66211H4.76758V12H3.8252V4.89062H6.44727C6.98438 4.89062 7.43848 4.9834 7.80957 5.16895C8.18066 5.35449 8.46224 5.61165 8.6543 5.94043C8.84635 6.26595 8.94238 6.63867 8.94238 7.05859C8.94238 7.51432 8.84635 7.90332 8.6543 8.22559C8.46224 8.54785 8.18066 8.79362 7.80957 8.96289C7.43848 9.12891 6.98438 9.21191 6.44727 9.21191Z"
                fill="#333333"
            />
            <path
                d="M9.85745 9.41699V9.30469C9.85745 8.92383 9.91279 8.57064 10.0235 8.24512C10.1341 7.91634 10.2937 7.63151 10.502 7.39062C10.7103 7.14648 10.9626 6.95768 11.2588 6.82422C11.555 6.6875 11.8871 6.61914 12.2549 6.61914C12.626 6.61914 12.9597 6.6875 13.2559 6.82422C13.5554 6.95768 13.8093 7.14648 14.0176 7.39062C14.2292 7.63151 14.3903 7.91634 14.501 8.24512C14.6117 8.57064 14.667 8.92383 14.667 9.30469V9.41699C14.667 9.79785 14.6117 10.151 14.501 10.4766C14.3903 10.8021 14.2292 11.0869 14.0176 11.3311C13.8093 11.5719 13.557 11.7607 13.2608 11.8975C12.9678 12.0309 12.6358 12.0977 12.2647 12.0977C11.8936 12.0977 11.5599 12.0309 11.2637 11.8975C10.9675 11.7607 10.7136 11.5719 10.502 11.3311C10.2937 11.0869 10.1341 10.8021 10.0235 10.4766C9.91279 10.151 9.85745 9.79785 9.85745 9.41699ZM10.7608 9.30469V9.41699C10.7608 9.68066 10.7917 9.92969 10.8535 10.1641C10.9154 10.3952 11.0082 10.6003 11.1319 10.7793C11.2588 10.9583 11.4167 11.0999 11.6055 11.2041C11.7943 11.305 12.014 11.3555 12.2647 11.3555C12.5121 11.3555 12.7285 11.305 12.9141 11.2041C13.1029 11.0999 13.2591 10.9583 13.3828 10.7793C13.5065 10.6003 13.5993 10.3952 13.6612 10.1641C13.7263 9.92969 13.7588 9.68066 13.7588 9.41699V9.30469C13.7588 9.04427 13.7263 8.7985 13.6612 8.56738C13.5993 8.33301 13.5049 8.1263 13.378 7.94727C13.2543 7.76497 13.098 7.62174 12.9092 7.51758C12.7237 7.41341 12.5056 7.36133 12.2549 7.36133C12.0075 7.36133 11.7894 7.41341 11.6006 7.51758C11.4151 7.62174 11.2588 7.76497 11.1319 7.94727C11.0082 8.1263 10.9154 8.33301 10.8535 8.56738C10.7917 8.7985 10.7608 9.04427 10.7608 9.30469Z"
                fill="#333333"
            />
            <path
                d="M17.3057 11.0625L18.6631 6.7168H19.2589L19.1417 7.58105L17.7598 12H17.1788L17.3057 11.0625ZM16.3926 6.7168L17.5499 11.1113L17.6329 12H17.0225L15.4893 6.7168H16.3926ZM20.5577 11.0771L21.6612 6.7168H22.5596L21.0264 12H20.421L20.5577 11.0771ZM19.3907 6.7168L20.7188 10.9893L20.8702 12H20.294L18.8731 7.57129L18.7559 6.7168H19.3907Z"
                fill="#333333"
            />
            <path
                d="M25.8477 12.0977C25.4799 12.0977 25.1463 12.0358 24.8468 11.9121C24.5505 11.7852 24.295 11.6077 24.0802 11.3799C23.8686 11.152 23.7058 10.8818 23.5919 10.5693C23.478 10.2568 23.421 9.91504 23.421 9.54395V9.33887C23.421 8.90918 23.4845 8.52669 23.6114 8.19141C23.7384 7.85286 23.9109 7.56641 24.129 7.33203C24.3471 7.09766 24.5945 6.92025 24.8712 6.7998C25.1479 6.67936 25.4343 6.61914 25.7306 6.61914C26.1082 6.61914 26.4337 6.68424 26.7071 6.81445C26.9838 6.94466 27.2101 7.12695 27.3858 7.36133C27.5616 7.59245 27.6918 7.86589 27.7765 8.18164C27.8611 8.49414 27.9034 8.83594 27.9034 9.20703V9.6123H23.9581V8.875H27.0001V8.80664C26.9871 8.57227 26.9382 8.3444 26.8536 8.12305C26.7722 7.90169 26.642 7.7194 26.463 7.57617C26.2839 7.43294 26.0398 7.36133 25.7306 7.36133C25.5255 7.36133 25.3367 7.40527 25.1642 7.49316C24.9916 7.5778 24.8435 7.70475 24.7198 7.87402C24.5961 8.04329 24.5001 8.25 24.4317 8.49414C24.3634 8.73828 24.3292 9.01986 24.3292 9.33887V9.54395C24.3292 9.7946 24.3634 10.0306 24.4317 10.252C24.5033 10.4701 24.6059 10.6621 24.7394 10.8281C24.8761 10.9941 25.0405 11.1243 25.2325 11.2188C25.4278 11.3132 25.6492 11.3604 25.8966 11.3604C26.2156 11.3604 26.4858 11.2952 26.7071 11.165C26.9285 11.0348 27.1222 10.8607 27.2882 10.6426L27.8351 11.0771C27.7211 11.2497 27.5763 11.4141 27.4005 11.5703C27.2247 11.7266 27.0082 11.8535 26.7511 11.9512C26.4972 12.0488 26.1961 12.0977 25.8477 12.0977Z"
                fill="#333333"
            />
            <path
                d="M30.0294 7.54688V12H29.1261V6.7168H30.005L30.0294 7.54688ZM31.6798 6.6875L31.6749 7.52734C31.6001 7.51107 31.5284 7.5013 31.4601 7.49805C31.395 7.49154 31.3201 7.48828 31.2355 7.48828C31.0271 7.48828 30.8432 7.52083 30.6837 7.58594C30.5242 7.65104 30.3891 7.74219 30.2784 7.85938C30.1678 7.97656 30.0799 8.11654 30.0148 8.2793C29.9529 8.4388 29.9122 8.61458 29.8927 8.80664L29.6388 8.95312C29.6388 8.63411 29.6697 8.33464 29.7316 8.05469C29.7967 7.77474 29.896 7.52734 30.0294 7.3125C30.1629 7.0944 30.3322 6.92513 30.5372 6.80469C30.7456 6.68099 30.993 6.61914 31.2794 6.61914C31.3445 6.61914 31.4194 6.62728 31.504 6.64355C31.5887 6.65658 31.6473 6.67122 31.6798 6.6875Z"
                fill="#333333"
            />
            <path
                d="M34.7775 12.0977C34.4097 12.0977 34.076 12.0358 33.7765 11.9121C33.4803 11.7852 33.2248 11.6077 33.0099 11.3799C32.7983 11.152 32.6356 10.8818 32.5216 10.5693C32.4077 10.2568 32.3507 9.91504 32.3507 9.54395V9.33887C32.3507 8.90918 32.4142 8.52669 32.5412 8.19141C32.6681 7.85286 32.8407 7.56641 33.0588 7.33203C33.2768 7.09766 33.5242 6.92025 33.8009 6.7998C34.0776 6.67936 34.3641 6.61914 34.6603 6.61914C35.0379 6.61914 35.3634 6.68424 35.6369 6.81445C35.9136 6.94466 36.1398 7.12695 36.3156 7.36133C36.4914 7.59245 36.6216 7.86589 36.7062 8.18164C36.7908 8.49414 36.8332 8.83594 36.8332 9.20703V9.6123H32.8879V8.875H35.9298V8.80664C35.9168 8.57227 35.868 8.3444 35.7834 8.12305C35.702 7.90169 35.5718 7.7194 35.3927 7.57617C35.2137 7.43294 34.9696 7.36133 34.6603 7.36133C34.4552 7.36133 34.2664 7.40527 34.0939 7.49316C33.9214 7.5778 33.7733 7.70475 33.6496 7.87402C33.5259 8.04329 33.4298 8.25 33.3615 8.49414C33.2931 8.73828 33.2589 9.01986 33.2589 9.33887V9.54395C33.2589 9.7946 33.2931 10.0306 33.3615 10.252C33.4331 10.4701 33.5356 10.6621 33.6691 10.8281C33.8058 10.9941 33.9702 11.1243 34.1623 11.2188C34.3576 11.3132 34.5789 11.3604 34.8263 11.3604C35.1453 11.3604 35.4155 11.2952 35.6369 11.165C35.8582 11.0348 36.0519 10.8607 36.2179 10.6426L36.7648 11.0771C36.6509 11.2497 36.506 11.4141 36.3302 11.5703C36.1545 11.7266 35.938 11.8535 35.6808 11.9512C35.4269 12.0488 35.1258 12.0977 34.7775 12.0977Z"
                fill="#333333"
            />
            <path
                d="M41.3859 10.9746V4.5H42.2941V12H41.4641L41.3859 10.9746ZM37.8312 9.41699V9.31445C37.8312 8.91081 37.8801 8.5446 37.9777 8.21582C38.0786 7.88379 38.2202 7.59896 38.4025 7.36133C38.5881 7.1237 38.8078 6.94141 39.0617 6.81445C39.3189 6.68424 39.6053 6.61914 39.9211 6.61914C40.2531 6.61914 40.5428 6.67773 40.7902 6.79492C41.0409 6.90885 41.2525 7.0765 41.425 7.29785C41.6008 7.51595 41.7391 7.77962 41.84 8.08887C41.9409 8.39811 42.0109 8.74805 42.05 9.13867V9.58789C42.0142 9.97526 41.9442 10.3236 41.84 10.6328C41.7391 10.9421 41.6008 11.2057 41.425 11.4238C41.2525 11.6419 41.0409 11.8096 40.7902 11.9268C40.5396 12.0407 40.2466 12.0977 39.9113 12.0977C39.6021 12.0977 39.3189 12.0309 39.0617 11.8975C38.8078 11.764 38.5881 11.5768 38.4025 11.3359C38.2202 11.0951 38.0786 10.8118 37.9777 10.4863C37.8801 10.1576 37.8312 9.80111 37.8312 9.41699ZM38.7394 9.31445V9.41699C38.7394 9.68066 38.7655 9.92806 38.8176 10.1592C38.8729 10.3903 38.9575 10.5938 39.0715 10.7695C39.1854 10.9453 39.3303 11.0837 39.506 11.1846C39.6818 11.2822 39.8918 11.3311 40.1359 11.3311C40.4354 11.3311 40.6812 11.2676 40.8732 11.1406C41.0685 11.0137 41.2248 10.846 41.342 10.6377C41.4592 10.4294 41.5503 10.2031 41.6154 9.95898V8.78223C41.5764 8.60319 41.5194 8.43066 41.4445 8.26465C41.3729 8.09538 41.2785 7.94564 41.1613 7.81543C41.0474 7.68197 40.9058 7.57617 40.7365 7.49805C40.5705 7.41992 40.3736 7.38086 40.1457 7.38086C39.8983 7.38086 39.6851 7.43294 39.506 7.53711C39.3303 7.63802 39.1854 7.77799 39.0715 7.95703C38.9575 8.13281 38.8729 8.33789 38.8176 8.57227C38.7655 8.80339 38.7394 9.05078 38.7394 9.31445Z"
                fill="#333333"
            />
            <path
                d="M46.512 4.5H47.4202V10.9746L47.342 12H46.512V4.5ZM50.9895 9.31445V9.41699C50.9895 9.80111 50.9439 10.1576 50.8528 10.4863C50.7616 10.8118 50.6282 11.0951 50.4524 11.3359C50.2766 11.5768 50.0618 11.764 49.8079 11.8975C49.554 12.0309 49.2626 12.0977 48.9338 12.0977C48.5986 12.0977 48.304 12.0407 48.0501 11.9268C47.7994 11.8096 47.5878 11.6419 47.4153 11.4238C47.2428 11.2057 47.1044 10.9421 47.0003 10.6328C46.8993 10.3236 46.8294 9.97526 46.7903 9.58789V9.13867C46.8294 8.74805 46.8993 8.39811 47.0003 8.08887C47.1044 7.77962 47.2428 7.51595 47.4153 7.29785C47.5878 7.0765 47.7994 6.90885 48.0501 6.79492C48.3007 6.67773 48.592 6.61914 48.9241 6.61914C49.2561 6.61914 49.5507 6.68424 49.8079 6.81445C50.065 6.94141 50.2799 7.1237 50.4524 7.36133C50.6282 7.59896 50.7616 7.88379 50.8528 8.21582C50.9439 8.5446 50.9895 8.91081 50.9895 9.31445ZM50.0813 9.41699V9.31445C50.0813 9.05078 50.0569 8.80339 50.0081 8.57227C49.9592 8.33789 49.8811 8.13281 49.7737 7.95703C49.6663 7.77799 49.5247 7.63802 49.3489 7.53711C49.1731 7.43294 48.9566 7.38086 48.6995 7.38086C48.4716 7.38086 48.273 7.41992 48.1038 7.49805C47.9378 7.57617 47.7962 7.68197 47.679 7.81543C47.5618 7.94564 47.4657 8.09538 47.3909 8.26465C47.3193 8.43066 47.2656 8.60319 47.2297 8.78223V9.95898C47.2818 10.1868 47.3665 10.4066 47.4837 10.6182C47.6041 10.8265 47.7636 10.9974 47.9622 11.1309C48.164 11.2643 48.413 11.3311 48.7092 11.3311C48.9534 11.3311 49.1617 11.2822 49.3342 11.1846C49.51 11.0837 49.6516 10.9453 49.759 10.7695C49.8697 10.5938 49.9511 10.3903 50.0032 10.1592C50.0553 9.92806 50.0813 9.68066 50.0813 9.41699Z"
                fill="#333333"
            />
            <path
                d="M53.7259 11.4531L55.1956 6.7168H56.1624L54.0433 12.8154C53.9944 12.9456 53.9293 13.0856 53.8479 13.2354C53.7698 13.3883 53.6689 13.5332 53.5452 13.6699C53.4215 13.8066 53.2718 13.9173 53.096 14.002C52.9235 14.0898 52.7168 14.1338 52.4759 14.1338C52.4043 14.1338 52.3131 14.124 52.2024 14.1045C52.0918 14.085 52.0136 14.0687 51.9681 14.0557L51.9632 13.3232C51.9892 13.3265 52.0299 13.3298 52.0852 13.333C52.1438 13.3395 52.1845 13.3428 52.2073 13.3428C52.4124 13.3428 52.5865 13.3151 52.7298 13.2598C52.873 13.2077 52.9934 13.1182 53.0911 12.9912C53.192 12.8675 53.2783 12.6966 53.3499 12.4785L53.7259 11.4531ZM52.6468 6.7168L54.0188 10.8184L54.2532 11.7705L53.6038 12.1025L51.6604 6.7168H52.6468Z"
                fill="#333333"
            />
            <defs>
                <clipPath id="clip0_765_10880">
                    <rect width="97" height="21" fill="white" transform="translate(4 20)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PoweredByEmisXLogo;
