import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Alert } from "@emisgroup/ui-alert";
import { Button } from "@emisgroup/ui-button";
import "./patientChangedAlert.scss";

type PatientChangedAlertProps = {
    currentPatient: string;
    referralPatient: string;
    onClose: () => void;
};

function getDisplayName(patient: string) {
    const patientObject = patient ? JSON.parse(patient) : {};
    const firstName =
        Array.isArray(patientObject?.PatientForenames) && patientObject.PatientForenames.length > 0
            ? patientObject.PatientForenames[0]
            : patientObject?.PatientForenames;
    const lastName = patientObject?.PatientSurname?.toUpperCase() || "";

    return `${lastName}, ${firstName} (${patientObject?.PatientTitle || ""})`;
}

function PatientChangedAlert({ currentPatient, referralPatient, onClose }: PatientChangedAlertProps) {
    const { t } = useTranslation();

    const handleOpenChange = React.useCallback(
        (open: boolean) => {
            if (!open) onClose();
        },
        [onClose],
    );

    return (
        <Alert
            data-testid="patient-changed-alert"
            type="warning"
            size="large"
            open={true}
            title={t(currentPatient ? "Patient.HasChangedTitle" : "Patient.HasClearedTitle")}
            buttons={
                // eslint-disable-next-line react/jsx-no-bind
                <Button data-testid="patient-changed-alert-close-button" onClick={() => onClose()} variant="filled">
                    {t("Close")}
                </Button>
            }
            onOpenChange={handleOpenChange}
        >
            <div className="patient-details">
                <div className="label-previous">{t("Patient.Previous")}:</div>
                <div className="name-previous">{getDisplayName(referralPatient)}</div>
                {currentPatient && <div className="label-current">{t("Patient.Current")}:</div>}
                {currentPatient && <div className="name-current">{getDisplayName(currentPatient)}</div>}
            </div>
            <p>{t(currentPatient ? "Patient.HasChangedMessage" : "Patient.HasClearedMessage")}</p>
        </Alert>
    );
}

export default PatientChangedAlert;
