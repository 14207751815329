import * as React from "react";
import { InfoError } from "@emisgroup/icons-react";

type ErrorProps = { title: string; text: string };
function Error({ title, text }: ErrorProps) {
    return (
        <div className="error-container" data-testid="query-error">
            <InfoError title={title} />
            <div className="error-title">{title}</div>
            <div className="error-text">{text}</div>
        </div>
    );
}

export default Error;
