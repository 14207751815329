import { NavigateToUrl } from "@emisgroup/single-spa-library";
import { endReferralSession } from "./referralSessionUtils";

export const DASHBOARD_URL = "./dashboard";

export const navigateToDashboard = (isRunningInApplicationFrame: boolean, navigate: any) => {
    endReferralSession();

    if (isRunningInApplicationFrame) {
        NavigateToUrl(DASHBOARD_URL);
    } else {
        navigate(-1);
    }
};
