import { CareProviderAddress } from "../types";

export type CareProviderOpeningHours = {
    day: string;
    open?: string;
    close?: string;
    isClosed?: boolean;
};

type DayRecord = { name: string; abbreviation: string };
const DAYS: DayRecord[] = [
    { name: "Monday", abbreviation: "mo" },
    { name: "Tuesday", abbreviation: "tu" },
    { name: "Wednesday", abbreviation: "we" },
    { name: "Thursday", abbreviation: "th" },
    { name: "Friday", abbreviation: "fr" },
    { name: "Saturday", abbreviation: "sa" },
    { name: "Sunday", abbreviation: "su" },
];

function getHours(openingHours: string) {
    if (!openingHours) {
        return { isClosed: true };
    }

    const [open, close] = openingHours?.split("-");
    return { open, close };
}

function getAbbreviatedDayFromHoursString(openingHours: string) {
    const [abbreviatedDay] = openingHours.split(" ");
    return abbreviatedDay?.toLowerCase();
}

const getAllOpeningHourRangesForDay = (timeRangesForCurrentDay: string[], name: string): CareProviderOpeningHours[] => {
    return timeRangesForCurrentDay.map((hoursForDay, index) => {
        const [, hoursString] = hoursForDay.split(" ");
        const day = index === 0 ? name : "";
        return { day, ...getHours(hoursString) };
    });
};

const daysReducer =
    (openingHours: string[]) =>
    (hours: CareProviderOpeningHours[], { name, abbreviation }: DayRecord): CareProviderOpeningHours[] => {
        const timeRangesForCurrentDay = openingHours.filter(o => getAbbreviatedDayFromHoursString(o) === abbreviation);
        if (timeRangesForCurrentDay.length == 0) {
            return [...hours, { day: name, isClosed: true }];
        }

        return [...hours, ...getAllOpeningHourRangesForDay(timeRangesForCurrentDay, name)];
    };

export function extractOpeningHours(openingHours: string[]): CareProviderOpeningHours[] {
    const reducer = daysReducer(openingHours);
    return DAYS.reduce(reducer, [] as CareProviderOpeningHours[]);
}

export function getMapUrl(fromTerm: string, toAddress: string) {
    return `${process.env.APP_MAPS_URL}/?api=1&origin=${fromTerm}&destination=${toAddress}`;
}

export function getAddressLine(address: CareProviderAddress) {
    const addressParts = address.streetAddress?.split(",")?.map(x => x.trim()) ?? [];
    return [...addressParts, address.postalCode].filter(Boolean).join(", ");
}
