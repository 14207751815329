import { useTranslation } from "@emisgroup/application-intl";
import { Dropdown, IListItem } from "@emisgroup/ui-kit-react";
import React from "react";

const genderOptions: IListItem[] = [
    {
        text: "",
        value: "",
    },
    {
        text: "Male",
        value: "male",
    },
    {
        text: "Female",
        value: "female",
    },
    {
        text: "Unknown",
        value: "unknown",
    },
];

interface GenderFieldProps {
    id: string;
    error: string;
    value: string;
}

const GenderField = ({ id, error, value }: GenderFieldProps) => {
    const { t } = useTranslation();
    return (
        <div className="nhsuk-form-group">
            <label className="nhsuk-label" htmlFor="gender">
                {t("PatientDetails.Gender")}
            </label>
            <div className="nhsuk-hint">{t("PatientDetails.GenderTooltip")}</div>
            {error && (
                <span className="nhsuk-error-message">
                    <span className="field-validation-error nhsuk-u-visually-hidden">Error:</span>
                    {error}
                </span>
            )}
            <div className="info-component">
                <Dropdown
                    className="gender-dropdown nhsuk-select"
                    dataSource={genderOptions}
                    data-testid="gender"
                    id={id}
                    name="gender"
                    invalid={!!error}
                    value={value}
                />
            </div>
        </div>
    );
};

export default GenderField;
