import * as React from "react";
import { IElementProps } from "../../Types";
import packageJson from "../../../package.json";
import { EnvironmentContext } from "../../environmentContext";
import LocalServicesHeader from "./localServicesHeader";
import LocalServicesFooter from "./localServicesFooter";
import CentredContent from "../centredContent";
import "./rootContainer.scss";

type Props = IElementProps & { centreContent?: boolean; children: React.ReactNode | React.ReactNode[] };

const RootContainer = ({ className, centreContent = false, children }: Props) => {
    const { isRunningInApplicationFrame, isPatientLed } = React.useContext(EnvironmentContext);

    const pageClass = `root-container ${isRunningInApplicationFrame ? "app-frame" : "standalone"}`;

    return (
        <div className={`${pageClass} ${className || ""}`}>
            {isPatientLed && <LocalServicesHeader />}
            <div className="container-body">
                {centreContent && <CentredContent>{children}</CentredContent>}
                {!centreContent && children}
                <div className="version">{`Version: ${packageJson.version}`}</div>
            </div>
            {isPatientLed && <LocalServicesFooter />}
        </div>
    );
};

export default RootContainer;
