import { useTranslation } from "@emisgroup/application-intl";
import { Textarea } from "@emisgroup/ui-kit-react";
import React from "react";

interface NotesFieldProps {
    id: string;
    error: string;
    value: string;
}

const NotesField = ({ id, error, value }: NotesFieldProps) => {
    const { t } = useTranslation();
    return (
        <div className={`nhsuk-form-group${error ? " nhsuk-form-group--error" : ""}`}>
            <label className="nhsuk-label" htmlFor="notes">
                {t("PatientDetails.Notes")}
            </label>
            {error && (
                <span className="nhsuk-error-message">
                    <span className="field-validation-error nhsuk-u-visually-hidden" id="notes-error">
                        Error:
                    </span>
                    {error}
                </span>
            )}

            <Textarea id={id} data-testid="notes" name="notes" value={value} className="nhsuk-textarea" />
        </div>
    );
};

export default NotesField;
