import * as React from "react";
import { IntlProvider as AppIntlProvider } from "@emisgroup/application-intl";
import referralMessages from "./locales/en-GB/translation.json";
import { enGBMessages as enRunner } from "@emisgroup/clint-templates-runner/lib/locales/en-GB/translation";
import { enGBMessages as enCommon } from "@emisgroup/clint-templates-common/lib/locales/en-GB/translation";
import { IChildrenProp } from "./Types";

export const applicationNamespace = "translate";

export const resources = {
    "en-GB": {
        [applicationNamespace]: { ...enCommon, ...enRunner, ...referralMessages },
    },
};

type Props = IChildrenProp;

/**
 * Responsible for rendering the IntlProvider component
 */
const IntlProvider: React.FC<Props> = (props: Props) => {
    return (
        <AppIntlProvider resources={resources} defaultNS={applicationNamespace}>
            {props.children}
        </AppIntlProvider>
    );
};

export { IntlProvider };
