"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const application_session_react_1 = require("@emisgroup/application-session-react");
const application_session_management_1 = require("@emisgroup/application-session-management");
const PATIENT_SESSION_ITEM = application_session_management_1.SessionItems.Patient;
const EXTENSION_REGISTRATION_EVENT = "__PatientAccessConnectInitialised__";
const EXTENSION_READY_EVENT = "__PatientAccessConnectReady__";
function usePacExtension() {
    const sessionContext = (0, application_session_react_1.useSessionContext)();
    (0, react_1.useEffect)(() => {
        const handleExtensionReady = () => {
            const patientContext = sessionContext.GetItem(PATIENT_SESSION_ITEM);
            document.dispatchEvent(new CustomEvent(EXTENSION_REGISTRATION_EVENT, {
                detail: { patient: patientContext || null, openApiHub: process.env.APP_OPEN_API_URL || null },
            }));
        };
        document.addEventListener(EXTENSION_READY_EVENT, handleExtensionReady);
        return () => {
            document.removeEventListener(EXTENSION_READY_EVENT, handleExtensionReady);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (sessionContext.GetItem(application_session_management_1.SessionItems.SubjectERN)) {
            sessionContext.RemoveItem(application_session_management_1.SessionItems.SubjectERN);
        }
    }, [sessionContext]);
}
exports.default = usePacExtension;
