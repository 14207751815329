import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";
import { usePacExtension } from "@emisgroup/patient-connect-common-react";
import { IElementProps } from "../../Types";
import { PatientReferral } from "../Referral/patientReferral";
import usePatientChangeMonitor from "../usePatientChangeMonitor";
import { getReferralSessionPatient } from "../referralSessionUtils";
import PatientChangedAlert from "./patientChangedAlert";
import PatientChangeWarning from "./patientChangeWarning";
import useService from "./useService";
import Error from "./error";
import { AssessmentProvider } from "../assessmentContext";
import { DeclineType } from "../../api/referrals";
import { PatientDetailsContext } from "../patientDetailsContext";
import { ServiceContext } from "../serviceContext";
import { navigateToDashboard } from "../navigationUtils";
import { EnvironmentContext } from "../../environmentContext";
import DeclineReferralDialog from "../Referral/declineReferralDialog";
import DiscardReferralDialog from "../Referral/discardReferralDialog";
import SubmitReferral from "../Referral/submitReferral";
import { TelemetryContext } from "../../telemetry";
import usePatientGuid from "../usePatientGuid";
import PatientLedServiceError from "./patientLedServiceError";
import PracticeConfirmation from "./practiceConfirmation";
import RootContainer from "../RootContainer/rootContainer";
import { ReferralSubmissionContext } from "../referralSubmissionContext";
import CentredContent from "../centredContent";
import NotRegisteredAtPractice from "./notRegisteredAtPractice";
import usePatientDetails from "../usePatientDetails";
import { SelectedCareProviderProvider } from "../selectedCareProviderContext";
import { RedirectsContext, ReferralOutcome } from "../../context/redirects";
import { ComponentContainer } from "@emisgroup/clint-templates-common";
import OrgServices from "../OrgServices/OrgServices";

const TemplateRunner = React.lazy(() => import("./templateRunner"));

type Props = IElementProps;
/**
 * Responsible for rendering the home component
 */
export const Home = ({ className }: Props) => {
    const { isRunningInApplicationFrame, isPatientLed: isEnvironmentPatientLed } = React.useContext(EnvironmentContext);
    const { referralRedirect } = React.useContext(RedirectsContext);
    usePacExtension();
    usePatientDetails();
    const navigate = useNavigate();
    const location = useLocation();
    const queryStringParams = new URLSearchParams(location.search);
    const { organisation, serviceId, patientLedEnabled, serviceName, onServiceDetails } =
        React.useContext(ServiceContext);
    const { isLoading: patientLoading, patientDetails } = React.useContext(PatientDetailsContext);
    const { isSubmitting, declineType, careProvider, onSubmit } = React.useContext(ReferralSubmissionContext);
    const { t } = useTranslation();
    const centreComponentRef = React.useRef(true);
    const { loading, loadingError, assessmentTemplate, assessmentComplete, onAssessmentComplete } = useService(
        queryStringParams,
        serviceId,
        onServiceDetails,
    );

    const { patientChanged, currentPatient, clearPatientChanged } = usePatientChangeMonitor();
    const [showPatientChangeWarning, setShowPatientChangeWarning] = React.useState(!isEnvironmentPatientLed);
    const [showDecline, setShowDecline] = React.useState(false);
    const [practiceConfirmed, setPracticeConfirmed] = React.useState<boolean | null>(null);

    const { trackBackToDashboard } = React.useContext(TelemetryContext);

    const patientGuid = usePatientGuid();
    const backToDashboard = React.useCallback(() => {
        trackBackToDashboard({ patient: patientGuid, serviceId });
        navigateToDashboard(isRunningInApplicationFrame, navigate);
    }, [patientGuid, serviceId, isRunningInApplicationFrame, navigate]);

    const handleClosePatientAlert = () => {
        clearPatientChanged();
        backToDashboard();
    };

    const handlePatientLedDecline = React.useCallback(() => {
        referralRedirect(ReferralOutcome.Declined);
    }, [referralRedirect]);

    const onTemplateDecline = React.useCallback(() => setShowDecline(true), [setShowDecline]);
    const closeDecline = React.useCallback(() => setShowDecline(false), [setShowDecline]);
    const declineDialog = isEnvironmentPatientLed ? (
        <DiscardReferralDialog onCancel={closeDecline} onDiscarded={handlePatientLedDecline} />
    ) : (
        <DeclineReferralDialog onCancel={closeDecline} />
    );

    const onTemplateSuccess = React.useCallback(() => onAssessmentComplete(true), [onAssessmentComplete]);
    const onTemplateStop = React.useCallback(() => {
        if (isEnvironmentPatientLed) referralRedirect(ReferralOutcome.Ineligible);
        else onSubmit({ declineType: DeclineType.Ineligible });
    }, [referralRedirect, onsubmit]);

    const onBack = React.useCallback(() => {
        if (assessmentTemplate) {
            onAssessmentComplete(false);
        } else {
            backToDashboard();
        }
    }, [assessmentTemplate, onAssessmentComplete, backToDashboard]);

    const component = () => {
        centreComponentRef.current = true;
        switch (true) {
            case patientChanged:
                return (
                    <PatientChangedAlert
                        data-testid="patient-changed-alert"
                        currentPatient={currentPatient}
                        referralPatient={getReferralSessionPatient()}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClose={handleClosePatientAlert}
                    />
                );
            case patientLoading:
                return (
                    <ProgressSpinnerLarge
                        data-testid="loading-patient"
                        text={t("PatientDetails.LoadingPatientDetails")}
                    />
                );
            case isSubmitting:
                return (
                    <SubmitReferral
                        patientDetails={patientDetails}
                        declineType={declineType}
                        careProvider={careProvider}
                    />
                );
            case isEnvironmentPatientLed && !serviceId:
                return <OrgServices />;
            case loading:
                return <ProgressSpinnerLarge data-testid="loading-assessment" text={t("Referral.LoadingAssessment")} />;
            case Boolean(loadingError):
                return <Error title={t("Referral.LoadingErrorTitle")} detail={loadingError} />;
            case isEnvironmentPatientLed && !patientLedEnabled:
                return <PatientLedServiceError serviceName={serviceName} />;
            case isEnvironmentPatientLed && practiceConfirmed === null && organisation !== null:
                return <PracticeConfirmation onContinue={setPracticeConfirmed} />;
            case isEnvironmentPatientLed && practiceConfirmed === false:
                return <NotRegisteredAtPractice />;
            case !assessmentComplete && Boolean(assessmentTemplate):
                centreComponentRef.current = false;
                return (
                    <React.Suspense fallback={<div />}>
                        {showDecline && declineDialog}
                        <TemplateRunner
                            templateDefinition={assessmentTemplate as ComponentContainer}
                            onTemplateSuccess={onTemplateSuccess}
                            onTemplateCancel={backToDashboard}
                            onTemplateStop={onTemplateStop}
                            onTemplateDecline={onTemplateDecline}
                        />
                    </React.Suspense>
                );

            default:
                return <PatientReferral onBack={onBack} />;
        }
    };

    const currentComponent = component();

    return (
        <RootContainer className={className}>
            <AssessmentProvider>
                <SelectedCareProviderProvider>
                    {showPatientChangeWarning && currentPatient && (
                        // eslint-disable-next-line react/jsx-no-bind
                        <PatientChangeWarning onDismiss={() => setShowPatientChangeWarning(false)} />
                    )}
                    {centreComponentRef.current === true ? (
                        <CentredContent>{currentComponent}</CentredContent>
                    ) : (
                        currentComponent
                    )}
                </SelectedCareProviderProvider>
            </AssessmentProvider>
        </RootContainer>
    );
};

export default Home;
