import React from "react";
import "./localServicesHeader.scss";
import LocalServicesIcon from "./localServices.png";
import { ServiceContext } from "../serviceContext";
import { buildAddressSummary } from "../utils";

function LocalServicesHeader() {
    const { organisation } = React.useContext(ServiceContext);
    const addressSummary = organisation?.address ? buildAddressSummary(organisation.address) : "";

    return (
        <div role="banner" className="local-services-header">
            <div className="content">
                <div className="local-services">
                    <img className="icon" src={LocalServicesIcon} alt="local services" />
                    <span className="service-title" />
                    Local<span className="service-bold-title">Services</span>
                </div>
                <div data-testid="organisation-name" className="organisation-name">
                    {organisation?.name}
                </div>
                <div data-testid="address-summary">{addressSummary}</div>
            </div>
        </div>
    );
}

export default LocalServicesHeader;
