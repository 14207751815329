import * as React from "react";
import { DeclineType } from "../api/referrals";
import { CareProviderRecord } from "./types";

type SubmitOptions = {
    declineType?: DeclineType;
    careProvider?: CareProviderRecord;
};

type SubmitState = SubmitOptions & { isSubmitting?: boolean };

type ReferralSubmissionContextType = SubmitState & { onSubmit?: (options: SubmitOptions) => void };

export const ReferralSubmissionContext = React.createContext<ReferralSubmissionContextType>({});

type ReferralSubmissionProps = { children: React.ReactNode | React.ReactNode[] };

export function ReferralSubmissionProvider({ children }: ReferralSubmissionProps) {
    const [submitState, setSubmitState] = React.useState<SubmitState>({});

    const handleSubmit = (options: SubmitOptions) => {
        setSubmitState({
            ...options,
            isSubmitting: true,
        });
    };

    return (
        <ReferralSubmissionContext.Provider
            value={{
                ...submitState,
                onSubmit: handleSubmit,
            }}
        >
            {children}
        </ReferralSubmissionContext.Provider>
    );
}
