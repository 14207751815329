import { ComponentContainer } from "@emisgroup/clint-templates-common";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { EnvironmentContext } from "../../environmentContext";
import { GetResult, Service } from "../../api/getService";
import { TelemetryContext } from "../../telemetry";
import { ApiContext } from "../../context/api";

const NOT_FOUND = 404;

export default function useService(
    queryStringParams: URLSearchParams,
    serviceId: string,
    onServiceDetails: (service: Service) => void,
) {
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState("");
    const [assessmentTemplate, setAssessmentTemplate] = useState<ComponentContainer | null>(null);
    const [assessmentComplete, setAssessmentComplete] = useState<boolean>(false);
    const { t } = useTranslation();
    const { trackException } = useContext(TelemetryContext);
    const { getService, isReady } = useContext(ApiContext);
    const { isPatientLed: isPatientLedEnvironment } = useContext(EnvironmentContext);
    const ignoreAssessment = !isPatientLedEnvironment && queryStringParams.get("assess") === "false";

    const handleServiceResponse = serviceResult => {
        if (serviceResult.service) {
            onServiceDetails(serviceResult.service);
        }
        const {
            service: { assessmentTemplateDefinition: template, mandatoryAssessment },
        } = serviceResult;

        if (mandatoryAssessment || (!ignoreAssessment && template)) {
            setAssessmentTemplate(template);
        } else {
            setAssessmentTemplate(null);
        }
    };

    useEffect(() => {
        if (!serviceId || !isReady) {
            return;
        }

        const retrieveService = async () => {
            try {
                setLoadingError("");
                setLoading(true);

                const serviceResult = await getService(serviceId);

                if (serviceResult.status === NOT_FOUND && isPatientLedEnvironment) {
                    onServiceDetails({ enablePatientLed: false });
                    return;
                }

                if (serviceResult.getResult === GetResult.Fail) {
                    setLoadingError(t("Referral.LoadingError"));
                    return;
                }

                handleServiceResponse(serviceResult);
            } catch (exception) {
                trackException({ exception });
                setLoadingError(t("Referral.LoadingErrorTitle"));
            } finally {
                setLoading(false);
            }
        };

        retrieveService();
    }, [serviceId, ignoreAssessment, isReady]);

    const onAssessmentComplete = (complete: boolean) => {
        setAssessmentComplete(complete);
    };

    return { loading, loadingError, assessmentTemplate, assessmentComplete, onAssessmentComplete };
}
