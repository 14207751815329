import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { TIMEOUT_PATH } from "../../constants";
import { EnvironmentContext } from "../../environmentContext";
import { TelemetryContext } from "../../telemetry";
import { PatientDetailsContext } from "../patientDetailsContext";

const MOUSE_EVENT = "mouseup";
const KEY_EVENT = "keyup";

const timerDisabled = () => {
    /* timer disabled in practice-led mode */
};

export const useActivityTimeout = (onExpire: () => void, timeoutSeconds: number) => {
    const getExpiryTimestamp = () => {
        const expiryTimestamp = new Date();
        expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + timeoutSeconds);
        return expiryTimestamp;
    };

    const { restart, pause, isRunning } = useTimer({
        expiryTimestamp: getExpiryTimestamp(),
        onExpire,
    });

    const restartTimeout = useCallback(() => {
        if (isRunning) restart(getExpiryTimestamp());
    }, [isRunning, restart]);

    const stopTimeout = () => pause();

    useEffect(() => {
        document.addEventListener(MOUSE_EVENT, restartTimeout);
        document.addEventListener(KEY_EVENT, restartTimeout);
        return () => {
            document.removeEventListener(MOUSE_EVENT, restartTimeout);
            document.removeEventListener(KEY_EVENT, restartTimeout);
        };
    }, [restartTimeout]);

    return { restartTimeout, stopTimeout };
};

export const getPatientActivityTimeout = () =>
    process.env.APP_ACTIVITY_TIMEOUT_MIN ? Number(process.env.APP_ACTIVITY_TIMEOUT_MIN) : 10;

const usePatientActivityTimeout = () => {
    const { trackPatientTimeout } = useContext(TelemetryContext);
    const { isPatientLed } = useContext(EnvironmentContext);
    const { clearPatientDetails } = useContext(PatientDetailsContext);
    const navigate = useNavigate();

    if (!isPatientLed) return { restartTimeout: timerDisabled, stopTimeout: timerDisabled };

    const onExpire = () => {
        clearPatientDetails();
        trackPatientTimeout({});
        navigate(TIMEOUT_PATH);
    };

    return useActivityTimeout(onExpire, getPatientActivityTimeout() * 60);
};

export default usePatientActivityTimeout;
