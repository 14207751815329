import * as React from "react";
const colors = new Map([["primary", "var(--primary)"], ["primary-dark", "var(--primary-dark)"], ["success", "var(--positive)"], ["danger", "var(--negative)"], ["white", "var(--accent-bright)"], ["mono", "var(--text)"]]);
const sizes = new Map([["small", "1rem"], ["medium", "1.5rem"], ["large", "2rem"], ["x-large", "3rem"]]);
const icBaselineWarningAmber = ({
  title,
  titleId,
  ...props
}) => {
  const color = props.color === undefined ? "currentColor" : colors.get(props.color) || props.color;
  const style = {
    color,
    fill: color,
    height: props.size === undefined ? sizes.get("medium") : sizes.get(props.size) || props.size,
    width: props.size === undefined ? sizes.get("medium") : sizes.get(props.size) || props.size,
    verticalAlign: "middle"
  };
  props = {
    style,
    ...props
  };
  titleId = titleId || props.id;
  return <svg viewBox="0 0 24 24" width="1.2em" height="1.2em" aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M12 5.99L19.53 19H4.47zM12 2L1 21h22z" /><path fill="currentColor" d="M13 16h-2v2h2zm0-6h-2v5h2z" /></svg>;
};
icBaselineWarningAmber.displayName = "Icon";
export default icBaselineWarningAmber;