export default {
    id: "template",
    type: "template",
    label: "No template",
    columnCount: 1,
    members: [
        {
            id: "131a41c6-5891-4473-b013-e75f9c9ed230",
            type: "panel",
            label: "Something went wrong",
            columnIndex: 0,
            rule: null,
            columnCount: 1,
            members: [
                {
                    id: "4c748393-3758-4d72-ab9f-1802ca9c9f6c",
                    type: "clinical content",
                    label: "",
                    columnIndex: 0,
                    rule: null,
                    members: [],
                    sectionTags: ["body"],
                    content: {
                        blocks: [
                            {
                                key: "43d1g",
                                text: "Try again later",
                                type: "header-three",
                                depth: 0,
                                inlineStyleRanges: [],
                                entityRanges: [],
                                data: {},
                            },
                        ],
                        entityMap: {},
                    },
                    columnCount: 0,
                },
            ],
            actionButtons: [
                {
                    style: "primary",
                    action: "discard",
                    label: "Close",
                    validate: false,
                },
            ],
        },
    ],
    change: {
        itemId: "131a41c6-5891-4473-b013-e75f9c9ed230",
    },
};
