import { useTranslation } from "@emisgroup/application-intl";
import { ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import { RibbonInfo } from "@emisgroup/icons-react";
import { MessageDialog } from "@emisgroup/patient-connect-common-react";
import React from "react";
import ReactMarkdown from "react-markdown";
import { GetResult, GetServicesResult } from "../../api/getServices";
import { ApiContext } from "../../context/api";
import Error from "../Query/error";

const OrgService = (props: { title: string; id: string; description?: string }) => {
    const { id, title, description } = props;
    const { t } = useTranslation();
    const [showDescriptionDialog, setShowDescriptionDialog] = React.useState(false);

    const handleDescriptionStateChange = React.useCallback(
        (show: boolean) => () => {
            setShowDescriptionDialog(show);
        },
        [setShowDescriptionDialog],
    );

    return (
        <>
            <div className="result-item nhsuk-u-margin-bottom-0 nhsuk-u-padding-top-5">
                <div className="nhsuk-grid-row results__details">
                    <div className="nhsuk-grid-column-one-half">
                        <div className="nhsuk-u-padding-top-0">
                            <div className="nhsuk-heading-s">
                                <a href={`?serviceId=${id}`} id="title">
                                    {title}
                                </a>

                                {description && (
                                    <RibbonInfo
                                        data-testid="service-description-button"
                                        className="service-description-button"
                                        title={t("Search.AboutThisService")}
                                        size="medium"
                                        onClick={handleDescriptionStateChange(true)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDescriptionDialog && (
                <MessageDialog
                    title={title}
                    content={<ReactMarkdown>{description ?? ""}</ReactMarkdown>}
                    onClose={handleDescriptionStateChange(false)}
                />
            )}
        </>
    );
};

const OrgServices = () => {
    const { getServices } = React.useContext(ApiContext);
    const { t } = useTranslation();
    const [result, setResult] = React.useState<GetServicesResult | undefined>();

    React.useEffect(() => {
        const retrieveServices = async () => {
            const response = await getServices();

            setResult(response);
        };

        retrieveServices();
    }, []);

    if (!result) return <ProgressSpinnerLarge text={t("Practice.LoadingServices")} />;

    if (result.getResult === GetResult.Fail && result.status === 404) {
        return <Error title={t("Practice.NotFound")} text={t("Practice.CheckUrl")} />;
    }

    if (result.getResult === GetResult.Success) {
        const servicesFound = result.services.length === 1 ? "Practice.OneService" : "Practice.SomeServices";
        return (
            <div>
                <div className="nhsuk-heading-l nhsuk-u-padding-top-5">
                    {t(result.services.length > 0 ? "Practice.AvailableServices" : "Practice.NoAvailableServices")}
                </div>
                <div className="nhsuk-label">{t(servicesFound, { services: result.services.length })}</div>
                {result.services.map(service => (
                    <OrgService
                        key={service.id}
                        title={service.title}
                        id={service.id}
                        description={service.description}
                    />
                ))}
            </div>
        );
    }

    return <Error title={t("Search.ErrorTitle")} text={t("Search.ErrorText")} />;
};

export default OrgServices;
