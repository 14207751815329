import * as React from "react";
import "./centredContent.scss";

type Props = { children: React.ReactNode | React.ReactNode[] };

const CentredContent = ({ children }: Props) => {
    return (
        <div className="centred-content">
            <div className="content">{children}</div>
        </div>
    );
};

export default CentredContent;
