import { ExceptionTelemetry } from "../telemetry";
import fetchWithRetry from "./fetchWithRetry";

const numberOfRetries = Number(process.env.APP_CORE_API_RETRIES ?? 3);
const retryWaitMilliseconds = Number(process.env.APP_CORE_API_RETRY_WAIT_MS ?? 500);

export enum GetResult {
    Success,
    Fail,
}

export type QueryResult = { getResult: GetResult.Success; data: any } | { getResult: GetResult.Fail; status: number };

async function doQuery(
    url: string,
    trackException: (event: ExceptionTelemetry) => void,
    getRequestInit: () => Promise<RequestInit>,
): Promise<QueryResult> {
    try {
        const response = await fetchWithRetry(url, await getRequestInit(), numberOfRetries, retryWaitMilliseconds);

        if (!response.ok) {
            const responseText = await response.text();
            trackException({
                exception: new Error(
                    `Failed to execute query: status:${response.status}, statusText:${response.statusText}, responseText:${responseText}`,
                ),
            });
            return { getResult: GetResult.Fail, status: response.status };
        }

        return { getResult: GetResult.Success, data: await response.json() };
    } catch (exception) {
        exception.message = `${exception.message} (query)`;
        trackException({ exception });
        return { getResult: GetResult.Fail, status: 500 };
    }
}

export async function careProviderSearch(
    postCode: string,
    serviceId: string,
    queryApiUrl: string,
    trackException: (event: ExceptionTelemetry) => void,
    getRequestInit: () => Promise<RequestInit>,
): Promise<QueryResult> {
    return doQuery(
        `${queryApiUrl}/service/${serviceId}/careProvidersSearch/${postCode}`,
        trackException,
        getRequestInit,
    );
}

export async function organisationSearch(
    name: string,
    queryApiUrl: string,
    trackException: (event: ExceptionTelemetry) => void,
    getRequestInit: () => Promise<RequestInit>,
): Promise<QueryResult> {
    return doQuery(`${queryApiUrl}/config/search?name=${name}`, trackException, getRequestInit);
}
