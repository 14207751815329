const referralPatientKey = "referral_patient";

export function startReferralSession(referralPatient: string) {
    sessionStorage.setItem(referralPatientKey, referralPatient);
}

export function getReferralSessionPatient(): string {
    return sessionStorage.getItem(referralPatientKey);
}

export function endReferralSession() {
    sessionStorage.removeItem(referralPatientKey);
}
