import * as React from "react";

type AssessmentContextType = {
    assessmentTemplateResult: any;
    setAssessmentTemplateResult: (result: any) => void;
};

export const AssessmentContext = React.createContext<AssessmentContextType>({
    assessmentTemplateResult: null,
    setAssessmentTemplateResult: () => {},
});

type AssessmentProviderProps = {
    children: React.ReactNode | React.ReactNodeArray;
    initialAssessmentTemplateResult?: any;
};

export function AssessmentProvider({ children, initialAssessmentTemplateResult }: AssessmentProviderProps) {
    const [assessmentTemplateResult, setAssessmentTemplateResult] = React.useState<any>(
        initialAssessmentTemplateResult,
    );
    return (
        <AssessmentContext.Provider value={{ assessmentTemplateResult, setAssessmentTemplateResult }}>
            {children}
        </AssessmentContext.Provider>
    );
}
