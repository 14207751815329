import * as React from "react";
import { Organisation, Service } from "../api/getService";

type ServiceContextType = {
    orgId?: string;
    organisation?: Organisation;
    serviceId?: string;
    referralCreatedCodeId?: string;
    referralDeclinedCodeId?: string;
    referralErrorMessage?: string;
    patientLedEnabled?: boolean;
    serviceName?: string;
    referredRedirectUrl?: string;
    onServiceDetails?: (service: Service) => void;
};

export const ServiceContext = React.createContext<ServiceContextType>({});

type ServiceProps = { initialServiceId?: string; children: React.ReactNode | React.ReactNode[] };

export function ServiceProvider({ initialServiceId, children }: ServiceProps) {
    const [orgId, setOrgId] = React.useState<string>("");
    const [organisation, setOrganisation] = React.useState<Organisation>(null);
    const [serviceId] = React.useState<string>(initialServiceId);
    const [referralCreatedCodeId, setReferralCreatedCodeId] = React.useState<string>("");
    const [referralDeclinedCodeId, setReferralDeclinedCodeId] = React.useState<string>("");
    const [referralErrorMessage, setReferralErrorMessage] = React.useState<string>("");
    const [patientLedEnabled, setPatientLedEnabled] = React.useState<boolean>(false);
    const [serviceName, setServiceName] = React.useState<string>("");
    const [referredRedirectUrl, setReferredRedirectUrl] = React.useState<string>();

    const onServiceDetails = (service: Service) => {
        setReferralCreatedCodeId(service.referralCreatedCodeId);
        setReferralDeclinedCodeId(service.referralDeclinedCodeId);
        setReferralErrorMessage(service.referralErrorMessage);
        setServiceName(service.name);
        setPatientLedEnabled(service.enablePatientLed ?? false);
        setOrgId(service.orgId);
        setOrganisation(service.organisation);
        setReferredRedirectUrl(service.referredReturnUrl);
    };

    return (
        <ServiceContext.Provider
            value={{
                orgId,
                organisation,
                serviceId,
                referralCreatedCodeId,
                referralDeclinedCodeId,
                referralErrorMessage,
                patientLedEnabled,
                serviceName,
                referredRedirectUrl,
                onServiceDetails,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
}
