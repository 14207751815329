import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { IElementProps } from "../../Types";
import RootContainer from "../RootContainer/rootContainer";
import "./accessibilityStatement.scss";

type Props = IElementProps;

function AccessibilityStatement({ className }: Props) {
    const { t } = useTranslation();
    return (
        <RootContainer centreContent={true} className={className}>
            <div role="main" className="accessibility-statement">
                <div className="title">{t("AccessibilityStatement.Title")}</div>
                <div className="paragraph">{t("AccessibilityStatement.AppliesTo")}</div>
                <div className="paragraph">{t("AccessibilityStatement.WeWantAsMany")}</div>
                <div className="bullet-list">
                    <ol>
                        <li>{t("AccessibilityStatement.AccessibilityOptions.ColoursContrastFonts")}</li>
                        <li>{t("AccessibilityStatement.AccessibilityOptions.Zoom")}</li>
                        <li>{t("AccessibilityStatement.AccessibilityOptions.KeyboardNavigation")}</li>
                    </ol>
                </div>
                <div className="paragraph">{t("AccessibilityStatement.SimpleToUnderstand")}</div>
                <div className="sentence">
                    <a href="https://mcmw.abilitynet.org.uk/" target="_blank" rel="noreferrer">
                        {t("AccessibilityStatement.AbilityNet")}
                    </a>
                    <span>&nbsp;</span>
                    <span>{t("AccessibilityStatement.AbilityNetDescription")}</span>
                </div>

                <div className="sub-title">{t("AccessibilityStatement.HowAccessible")}</div>
                <div className="paragraph">{t("AccessibilityStatement.NotFullyAccessible")}</div>
                <div className="bullet-list">
                    <ol>
                        <li>{t("AccessibilityStatement.LackingAccessibilityOptions.PersonalDetailsForm")}</li>
                        <li>{t("AccessibilityStatement.LackingAccessibilityOptions.DateOfBirthField")}</li>
                    </ol>
                </div>
                <div className="paragraph">{t("AccessibilityStatement.IfYouNeedInformation")}</div>
                <div className="paragraph">{t("AccessibilityStatement.Commitment")}</div>

                <div className="paragraph">
                    <div className="sentence">
                        {t("AccessibilityStatement.Equality")}
                        <span>&nbsp;</span>
                        <a href="https://www.equalityadvisoryservice.com/" target="_blank" rel="noreferrer">
                            {t("AccessibilityStatement.EqualityAdvisory")}
                        </a>
                        <span>&nbsp;.</span>
                    </div>
                </div>

                <div className="sub-title">{t("AccessibilityStatement.TechnicalInformation")}</div>

                <div className="paragraph">{t("AccessibilityStatement.PublicSectorBodies")}</div>

                <div className="paragraph">
                    <div className="sentence">
                        <span>{t("AccessibilityStatement.PartiallyCompliantStart")}</span>
                        <span>&nbsp;</span>
                        <a href="https://www.w3.org/TR/WCAG21" target="_blank" rel="noreferrer">
                            {t("AccessibilityStatement.WebContentGuidelines")}
                        </a>
                        <span>&nbsp;</span>
                        {t("AccessibilityStatement.PartiallyCompliantEnd")}
                    </div>
                </div>
                <div className="bullet-list">
                    <ol>
                        <li>{t("AccessibilityStatement.NonCompliances.NoLinkShortDescription")}</li>
                        <li>{t("AccessibilityStatement.NonCompliances.NoButtonShortDescription")}</li>
                        <li>{t("AccessibilityStatement.NonCompliances.NonUniqueIDs")}</li>
                        <li>{t("AccessibilityStatement.NonCompliances.NoTitleInFrames")}</li>
                        <li>{t("AccessibilityStatement.NonCompliances.NoElementLabels")}</li>
                    </ol>
                </div>

                <div className="paragraph">{t("AccessibilityStatement.FailCriterion")}</div>
                <div className="paragraph">{t("AccessibilityStatement.Remediations")}</div>
            </div>
        </RootContainer>
    );
}

export default AccessibilityStatement;
