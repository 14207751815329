/* eslint-disable react/jsx-no-bind */
import { useTranslation } from "@emisgroup/application-intl";
import * as React from "react";
import { ProgressSpinnerSmall } from "@emisgroup/ui-kit-react";
import { SelectedCareProviderContext } from "../selectedCareProviderContext";
import Query from "../Query";
import Error from "../Query/error";
import { TelemetryContext } from "../../telemetry";
import { ApiContext } from "../../context/api";
import { QueryResult, GetResult } from "../../api/queries";
import BackLink from "../backLink";
import { extractOpeningHours, getAddressLine, getMapUrl } from "./utils";
import { CareProviderRecord } from "../types";
import { ServiceContext } from "../serviceContext";
import OpeningHours from "./openingHours";

type ResultProps = { term: string; careProvider: CareProviderRecord };
function Result({ term, careProvider, careProvider: { name, address, telephone, openingHours = [] } }: ResultProps) {
    const { t } = useTranslation();
    const { onSelect } = React.useContext(SelectedCareProviderContext);
    const addressLine = getAddressLine(address);

    const handleCareProviderSelected = e => {
        e.preventDefault();
        onSelect(careProvider);
    };

    const clearFocus = (e: any) => {
        e.currentTarget.blur();
    };

    return (
        <div className="result-item nhsuk-u-margin-bottom-0 nhsuk-u-padding-top-5">
            <div className="nhsuk-grid-row results__details">
                <div className="nhsuk-grid-column-one-half nhsuk-u-margin-bottom-5">
                    <div className="nhsuk-u-padding-top-0">
                        <button
                            data-testid="care-provider-list-item"
                            type="button"
                            className="care-provider-item nhsuk-heading-m"
                            onClick={handleCareProviderSelected}
                        >
                            {name}
                        </button>
                    </div>
                    <p className="nhsuk-list nhsuk-u-margin-bottom-2">{addressLine}</p>
                    <p className="nhsuk-list nhsuk-u-margin-bottom-2">{telephone}</p>
                    <div className="nhsuk-list nhsuk-u-margin-bottom-0">
                        <div className="results__item__link">
                            <a
                                href={getMapUrl(term, `${name},${addressLine}`)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="maplink"
                                onClick={clearFocus}
                            >
                                {t("Search.MapAndDirections")}{" "}
                            </a>
                            <span className="nhsuk-u-visually-hidden">
                                `${t("Search.For").toLowerCase()} ${name}`
                            </span>
                        </div>
                    </div>
                </div>
                <div className="nhsuk-grid-column-one-half nhsuk-u-margin-bottom-5">
                    <details className="nhsuk-details nhsuk-expander">
                        <summary onClick={clearFocus} className="nhsuk-details__summary">
                            <span className="nhsuk-details__summary-text">{t("Search.OpeningTimes")}</span>
                        </summary>
                        <div className="nhsuk-details__text">
                            <OpeningHours openingHours={extractOpeningHours(openingHours)} />
                        </div>
                    </details>
                </div>
            </div>
        </div>
    );
}

type ResultsViewProps = { term?: string; data: { careProvider: CareProviderRecord[] } };
function ResultsView({ term, data: { careProvider: results } }: ResultsViewProps) {
    const { t } = useTranslation();
    if (!results.length) {
        return (
            <p data-testid="no-results" className="no-results">
                {t("Search.NoResults")}
            </p>
        );
    }
    const providersFoundTitle = `${results.length} ${
        results.length === 1 ? t("Search.ProviderFound") : t("Search.ProvidersFound")
    }`;

    return (
        <div className="nhs-grid-row">
            <div className="nhs-grid-column-full">
                <h1 className="nhsuk-heading-l">
                    {t("Search.LocalServicesNear")} {term?.toUpperCase()}
                </h1>
                <h3>{t("Search.NameWarning")}</h3>

                <div className="nhsuk-label">{providersFoundTitle}</div>
                <ul className="result-list">
                    {results.map(result => (
                        <li key={result.id}>
                            <Result term={term} careProvider={result} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

function useCareProviderSearch(term: string, serviceId: string) {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<{ careProvider: CareProviderRecord[] } | null>(null);
    const [queryError, setQueryError] = React.useState(false);
    const [searchTermInvalid, setSearchTermInvalid] = React.useState(false);
    const { careProviderSearch } = React.useContext(ApiContext);
    const { trackInvalidPostcode, trackSearch, trackException } = React.useContext(TelemetryContext);

    React.useEffect(() => {
        const doSearch = async () => {
            setLoading(true);
            setQueryError(false);
            setSearchTermInvalid(false);
            try {
                const result: QueryResult = await careProviderSearch(term, serviceId);
                if (result.getResult === GetResult.Success) {
                    setData(result.data.data);
                    trackSearch({ term, results: result.data.data });
                } else if (result.status === 400) {
                    setSearchTermInvalid(true);
                    trackInvalidPostcode({ term });
                } else {
                    setQueryError(true);
                    trackSearch({ term, results: [] });
                }
            } catch (e) {
                setQueryError(true);
                trackException({ exception: e });
            } finally {
                setLoading(false);
            }
        };

        doSearch();
    }, [term]);

    return React.useMemo(
        () => ({
            search: { loading, data, term, error: queryError },
            searchTermInvalid,
        }),
        [loading, data, queryError, term],
    );
}

export type ResultsProps = {
    term: string;
    onBack?: () => void;
    onInvalid?: () => void;
};

function Results({ term, onBack, onInvalid }: ResultsProps) {
    const { serviceId } = React.useContext(ServiceContext);
    const { search, searchTermInvalid } = useCareProviderSearch(term, serviceId);

    React.useEffect(() => {
        if (onInvalid && searchTermInvalid) onInvalid();
    }, [searchTermInvalid]);
    const { t } = useTranslation();
    const errorComponent = () => <Error title={t("Search.ErrorTitle")} text={t("Search.ErrorText")} />;
    const loadingComponent = () => (
        <ProgressSpinnerSmall data-testid="search-spinner" className="search-spinner" text={t("Search.Searching")} />
    );

    return (
        <>
            {onBack && <BackLink onBack={onBack} />}

            <div className="results-panel">
                <Query
                    {...search}
                    errorComponent={errorComponent}
                    loadingComponent={loadingComponent}
                    dataComponent={ResultsView}
                />
            </div>
        </>
    );
}

export default Results;
