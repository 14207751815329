import { useTranslation } from "@emisgroup/application-intl";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EnvironmentContext } from "../environmentContext";
import { navigateToDashboard } from "./navigationUtils";

const useBackButton = () => {
    const { t } = useTranslation();
    const { isRunningInApplicationFrame, isPatientLed } = React.useContext(EnvironmentContext);
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigateToDashboard(isRunningInApplicationFrame, navigate);
    };

    const buttonText = isPatientLed ? t("View.Back") : t("View.BackToDashboard");
    const buttonProps = { onClick: handleBackClick };
    return { buttonText, buttonProps };
};

export default useBackButton;
