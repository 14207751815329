import * as React from "react";
import Application from "./App";
import { IntlProvider } from "./IntlProvider";
import { AddEmisWebEventHandlers, RemoveEmisWebEventHandlers } from "./events/handlers";
import { ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import { TokenState } from "@emisgroup/application-session-management";
import GetSessionContext from "./session/getSessionContext";
import { BrowserRouter } from "react-router-dom";

export const SignInPath = "/signin";

/**
 * Responsible for rendering the component as per route path
 */
export const AppLocal: React.FC = () => {
    const [sessionContext] = React.useState(GetSessionContext());
    const [isAuthenticated, setIsAuthenticated] = React.useState(sessionContext.accessToken.state === TokenState.Valid);

    React.useEffect(() => {
        AddEmisWebEventHandlers({
            sessionContext,
            setIsAuthenticated,
        });
        return () => {
            RemoveEmisWebEventHandlers();
        };
    }, []);

    const isAuthenticationRequired = process.env.APP_PATIENT_LED_REFERRAL !== "true";
    if (isAuthenticationRequired && !isAuthenticated)
        return <ProgressSpinnerLarge text="Waiting for authentication via Chrome Extension" />;

    return (
        <IntlProvider>
            <BrowserRouter>
                <Application isRunningInApplicationFrame={false} sessionContext={sessionContext} />
            </BrowserRouter>
        </IntlProvider>
    );
};

export default AppLocal;
