export enum Gender {
    Unknown = "unknown",
    Male = "male",
    Female = "female",
}

export enum EmailAssessmentOption {
    Yes = "Yes",
    No = "No",
}

export interface PatientDetails {
    firstName: string;
    lastName: string;
    mobilePhoneNumber: string;
    homePhoneNumber: string;
    email: string;
    postcode: string;
    addressLine1: string;
    gender: Gender;
    nhsNumber: string;
    dateOfBirth: Date;
    referralReason: string;
    notes: string;
    isThirdPartyContact: boolean;
    contactFirstName: string;
    contactLastName: string;
    emailAssessment?: EmailAssessmentOption;
    humanityCheck?: string;
}

type Identifier = {
    name: string;
    value: string;
};

export type CareProviderAddress = {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressCity: string;
    addressCountry: string;
    postalCode: string;
    streetAddress: string;
};

export type CareProviderRecord = {
    id: string;
    name: string;
    address: CareProviderAddress;
    email?: string;
    openingHours: string[];
    telephone?: string;
    identifier: Identifier[];
};

export type OrganisationAddress = {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressCity: string;
    addressCountry: string;
    postalCode: string;
};

export type OrganisationSearchRecord = {
    id: string;
    name: string;
    address: OrganisationAddress;
    baseUrl?: string;
};
