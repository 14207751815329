import { useContext, useEffect } from "react";
import { useSessionContext } from "@emisgroup/application-session-react";
import { PatientDetailsContext } from "./patientDetailsContext";
import { getPatientDetails } from "../queries/getPatientDetails";
import { EnvironmentContext } from "../environmentContext";
import { TelemetryContext } from "../telemetry";
import { PatientDetails } from "./types";
import usePatientGuid from "./usePatientGuid";

function usePatientDetails() {
    const currentPatientGuid = usePatientGuid();
    const sessionContext = useSessionContext();
    const { isPatientLed } = useContext(EnvironmentContext);
    const { updateIsLoading, updatePatientDetails } = useContext(PatientDetailsContext);
    const { trackException } = useContext(TelemetryContext);

    useEffect(() => {
        const loadPatientDetails = async (patientGuid: string, accessToken: string) => {
            try {
                updateIsLoading(true);
                return await getPatientDetails(process.env.APP_OPEN_API_URL, accessToken, patientGuid);
            } catch (e) {
                const domException = e as DOMException;
                if (domException.name !== "AbortError") {
                    trackException({ exception: e });
                }
                return undefined;
            } finally {
                updateIsLoading(false);
            }
        };

        const storePatientDetails = async () => {
            let patientDetails = undefined as PatientDetails;

            if (!isPatientLed && currentPatientGuid) {
                const accessToken = sessionContext?.accessToken?.value;
                if (accessToken) patientDetails = await loadPatientDetails(currentPatientGuid, accessToken);
            }
            updatePatientDetails(patientDetails);
        };

        storePatientDetails();
    }, []);
}

export default usePatientDetails;
