import { useTranslation } from "@emisgroup/application-intl";
import { DatePicker } from "@emisgroup/ui-kit-react";
import React from "react";

interface DateOfBirthFieldProps {
    id: string;
    error: string;
    onChange: (value: Date) => void;
    value: Date;
}

const DateOfBirthField = ({ id, error, onChange, value }: DateOfBirthFieldProps) => {
    const { t } = useTranslation();
    return (
        <div className={`nhsuk-form-group${error ? " nhsuk-form-group--error" : ""}`}>
            <label className="nhsuk-label" htmlFor="dateOfBirth">
                {t("PatientDetails.DateOfBirth.Label")}
            </label>

            {error && (
                <span className="nhsuk-error-message">
                    <span className="field-validation-error nhsuk-u-visually-hidden" id="dateOfBirth-error">
                        Error:
                    </span>
                    {error}
                </span>
            )}

            <DatePicker
                id={id}
                data-testid="dateOfBirth"
                dateType="day"
                mandatory={true}
                invalid={!!error}
                onSelectDate={onChange}
                selectedDate={value}
                className="date-picker-nhs"
            />
        </div>
    );
};

export default DateOfBirthField;
