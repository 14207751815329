import * as React from "react";

export type EnvironmentContextType = { isRunningInApplicationFrame: boolean; isPatientLed?: boolean };

export const EnvironmentContext = React.createContext<EnvironmentContextType>({
    isRunningInApplicationFrame: false,
    isPatientLed: false,
});

type EnvironmentProviderProps = {
    isRunningInApplicationFrame: boolean;
    isPatientLed?: boolean;
    children: React.ReactNode | React.ReactNodeArray;
};

export function EnvironmentProvider({ isRunningInApplicationFrame, isPatientLed, children }: EnvironmentProviderProps) {
    return (
        <EnvironmentContext.Provider value={{ isRunningInApplicationFrame, isPatientLed }}>
            {children}
        </EnvironmentContext.Provider>
    );
}
