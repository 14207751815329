import { useTranslation } from "@emisgroup/application-intl";
import React from "react";
import { OrganisationSearchRecord } from "../types";
import Result from "./result";

type ResultsViewProps = { term?: string; data: { organisations: OrganisationSearchRecord[] } };
function ResultsView({ term, data: { organisations: results } }: ResultsViewProps) {
    const { t } = useTranslation();
    if (!results.length) {
        return (
            <p data-testid="no-results" className="no-results">
                {t("Search.NoResults")}
            </p>
        );
    }
    const organisationsFoundTitle = `${results.length} ${
        results.length === 1 ? t("Search.PracticeFound") : t("Search.PracticesFound")
    }`;

    return (
        <div className="nhs-grid-row">
            <div className="nhs-grid-column-full">
                <h1 className="nhsuk-heading-l">
                    {t("Search.PracticesMatching")} {term}
                </h1>

                <div className="nhsuk-label">{organisationsFoundTitle}</div>
                <ul className="result-list">
                    {results.map(o => (
                        <li key={o.id}>
                            <Result organisationSearchRecord={o} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ResultsView;
