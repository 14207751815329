import * as React from "react";
import { CareProviderOpeningHours } from "./utils";

type OpenHoursLineProps = { open?: string; close?: string };
function OpenHoursLine({ open, close }: OpenHoursLineProps) {
    return (
        <>
            {open && <p className="open">{open}</p>}
            {close && (
                <>
                    <p className="to">to</p>
                    <p className="close">{close}</p>
                </>
            )}
        </>
    );
}

type OpeningProps = { openingHours: CareProviderOpeningHours };
function Opening({ openingHours: { day, isClosed, open, close } }: OpeningProps) {
    return (
        <>
            <p className="day">{day}</p>
            {isClosed ? <p className="closed">Closed</p> : <OpenHoursLine open={open} close={close} />}
        </>
    );
}

type OpeningHoursProps = { openingHours: CareProviderOpeningHours[] };
function OpeningHours({ openingHours }: OpeningHoursProps) {
    return (
        <div className="opening-hours">
            <div data-testid="care-provider-opening-hours" className="opening-list">
                {openingHours.map(hours => (
                    <Opening key={hours.day} openingHours={hours} />
                ))}
            </div>
        </div>
    );
}

export default OpeningHours;
