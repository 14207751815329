import * as React from "react";
import { Route, Routes as BrowserRoutes } from "react-router-dom";
import CookiePolicy from "../components/CookiePolicy/cookiePolicy";
import EmbeddedTemplateRunner from "../components/Home/embeddedTemplateRunner";
import { Home } from "../components/Home/Index";
import PatientTimeout from "../components/PatientTimeout/PatientTimeout";
import PrivacyNotice from "../components/PrivacyNotice/privacyNotice";
import AccessibilityStatement from "../components/AccessibilityStatement/accessibilityStatement";
import {
    ACCESSIBILITY_STATEMENT_PATH,
    ASSESS_PATH,
    COOKIE_POLICY_PATH,
    PRIVACY_NOTICE_PATH,
    TIMEOUT_PATH,
} from "../constants";
import { EnvironmentContext } from "../environmentContext";
import OrganisationSearch from "../components/OrganisationSearch";

export const Routes: React.FC = () => {
    const { isPatientLed } = React.useContext(EnvironmentContext);
    return (
        <BrowserRoutes>
            <Route path={TIMEOUT_PATH} element={<PatientTimeout />} />
            <Route path={COOKIE_POLICY_PATH} element={<CookiePolicy />} />
            <Route path={PRIVACY_NOTICE_PATH} element={<PrivacyNotice />} />
            <Route path={ACCESSIBILITY_STATEMENT_PATH} element={<AccessibilityStatement />} />
            <Route path={ASSESS_PATH} element={<EmbeddedTemplateRunner />} />
            <Route path="/*" element={<Home />} />
            {isPatientLed && <Route path="/" element={<OrganisationSearch />} />}
            {isPatientLed && <Route path="/search" element={<OrganisationSearch />} />}
        </BrowserRoutes>
    );
};
