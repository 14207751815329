/* eslint-disable react/jsx-no-bind */
import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { FormElement, TextInput } from "@emisgroup/ui-kit-react";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { Content, Dialog, DialogInner, Footer, Header } from "@emisgroup/ui-dialog";
import { PatientDetailsContext } from "../patientDetailsContext";
import { DeclineType } from "../../api/referrals";
import { ReferralSubmissionContext } from "../referralSubmissionContext";
import "./referral.scss";

type DeclineReferralDialogProps = {
    onCancel: () => void;
};

const DeclineReferralText = "Referral.DeclineReferral";

function DeclineReferralDialog({ onCancel }: DeclineReferralDialogProps) {
    const { t } = useTranslation();
    const [interacted, setInteracted] = React.useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState(true);

    const { patientDetails, updatePatientDetails } = React.useContext(PatientDetailsContext);
    const { onSubmit } = React.useContext(ReferralSubmissionContext);

    const referralReasonChanged = (value: string) => {
        const newValue = value.trim();
        updatePatientDetails({ ...patientDetails, referralReason: newValue });
        setInteracted(true);
    };

    const referralReasonBlur = () => {
        setInteracted(true);
    };

    const isReferralReasonValid = (patientDetails?.referralReason?.length ?? 0) > 0;

    const handleOpenChange = React.useCallback(
        (open: boolean) => {
            if (!open) onCancel();
        },
        [onCancel],
    );

    const handleConfirmDeclineReferral = () => {
        if (isReferralReasonValid) {
            setIsOpen(false);
            onSubmit({ declineType: DeclineType.PatientDeclined });
        }

        setInteracted(true);
    };

    const showReferralReasonAsInvalid = interacted && !isReferralReasonValid;
    const errorText = showReferralReasonAsInvalid ? t("Referral.MustEnterReferralReason") : undefined;

    return (
        <Dialog open={isOpen} onOpenChange={handleOpenChange} preventBackdropClose={true}>
            <DialogInner>
                <Header>{t(DeclineReferralText)}</Header>
                <Content className="decline-referral-dialog-content">
                    <div className="message-box">
                        <p>{t("Referral.PatientDeclinedOfferOfReferral")}</p>
                        <p>{t("Referral.RecordedOnTheirClinicalRecord")}</p>
                    </div>
                    <form
                        data-testid="decline-referral-form"
                        onSubmit={e => {
                            e.preventDefault();
                            handleConfirmDeclineReferral();
                        }}
                    >
                        <FormElement mandatory labelText={t("Referral.ReasonForReferral")} errorText={errorText}>
                            <TextInput
                                data-testid="referral-reason"
                                inputType="text"
                                value={patientDetails?.referralReason}
                                onChange={e => referralReasonChanged(e.currentTarget.value)}
                                onBlur={referralReasonBlur}
                                invalid={showReferralReasonAsInvalid}
                            />
                        </FormElement>
                    </form>
                </Content>
                <Footer>
                    <ButtonGroup className="left-aligned-dialog-button-group">
                        <Button
                            data-testid="confirm-decline-referral"
                            type="button"
                            variant="filled"
                            onClick={evt => {
                                handleConfirmDeclineReferral();
                                evt.stopPropagation();
                            }}
                            ariaLabel={t(DeclineReferralText)}
                        >
                            {t(DeclineReferralText)}
                        </Button>
                        <Button
                            data-testid="cancel-decline-referral"
                            type="button"
                            onClick={onCancel}
                            ariaLabel={t("Cancel")}
                        >
                            {t("Cancel")}
                        </Button>
                    </ButtonGroup>
                </Footer>
            </DialogInner>
        </Dialog>
    );
}

export default DeclineReferralDialog;
