import {
    EMAIL_REGEX,
    NAME_REGEX,
    PHONE_NUMBER_REGEX,
    POSTCODE_REGEX,
    FREE_TEXT_REGEX,
    TEN_DIGITS_REGEX,
} from "../regex";
import { FieldName, FieldValidator, FieldValidity, ValidationInput, ValidationResult, ValidatorMethod } from "./types";

const fakeNhsNumbers: string[] = [
    "0123456789",
    "9876543210",
    "0000000000",
    "1111111111",
    "2222222222",
    "3333333333",
    "4444444444",
    "5555555555",
    "6666666666",
    "7777777777",
    "8888888888",
    "9999999999",
];

function isValidNhsNumber(enteredValue: string): boolean {
    const value = enteredValue.replace(/\s/g, "");
    if (!value) {
        return false;
    }
    const tenDigitRegex = new RegExp(TEN_DIGITS_REGEX);
    if (!tenDigitRegex.test(value)) {
        return false;
    }

    let sum = 0;
    let factor = 10;

    for (let i = 0; i < value.length - 1; i += 1) {
        sum += Number(value[i]) * factor;
        factor -= 1;
    }

    const remainder = sum % 11;
    let checkDigit = 11 - remainder;

    if (checkDigit === 11) {
        checkDigit = 0;
    }

    if (checkDigit === 10) {
        return false;
    }

    return value.endsWith(String(checkDigit));
}

function isFakeNhsNumber(value: string): boolean {
    return fakeNhsNumbers.indexOf(value) >= 0;
}

function validateNhsNumber({ value }: ValidationInput): string {
    if (value && !isValidNhsNumber(value)) {
        return "PatientDetails.NhsNumber.Invalid";
    }

    if (value && isFakeNhsNumber(value)) {
        return "PatientDetails.NhsNumber.Fake";
    }

    return "";
}

function validateName({ value }: ValidationInput): string {
    const nameRegex = new RegExp(NAME_REGEX);
    return !nameRegex.test(value) ? "PatientDetails.Name.Invalid" : "";
}

function validatePhoneNumber(value: string): string {
    const phoneRegex = new RegExp(PHONE_NUMBER_REGEX);
    return !phoneRegex.test(value) ? "PatientDetails.PhoneNumber.Invalid" : "";
}

function validateEmail({ value }: ValidationInput): string {
    const emailRegex = new RegExp(EMAIL_REGEX);
    return value && !emailRegex.test(value) ? "PatientDetails.Email.Invalid" : "";
}

function validateFreeText({ value }: ValidationInput): string {
    const nameRegex = new RegExp(FREE_TEXT_REGEX);
    return !nameRegex.test(value) ? "PatientDetails.FreeTextInvalid" : "";
}

function validateEmailMatch(input: ValidationInput): string {
    return input.value !== input.patientDetails.email ? "PatientDetails.Email.Mismatch" : "";
}

function validateDateOfBirth({ value }: ValidationInput): string {
    if (!(value instanceof Date && !Number.isNaN(value))) {
        return "PatientDetails.DateOfBirth.Invalid";
    }
    if (value > new Date()) {
        return "PatientDetails.DateOfBirth.InFuture";
    }

    return "";
}

function validatePostcode({ value }: ValidationInput): string {
    const postcodeRegex = new RegExp(POSTCODE_REGEX);
    return !postcodeRegex.test(value) ? "PatientDetails.Postcode.Invalid" : "";
}

function validateHasValue({ value }: ValidationInput): string {
    return value ? "" : "PatientDetails.MandatoryField";
}

function validateMaxLength({ value }: ValidationInput): string {
    return (value?.length ?? 0) <= 1024 ? "" : "PatientDetails.MaxLength";
}

const valid = (field: FieldName): FieldValidity => ({ field });
const invalid = (field: FieldName, error: string): FieldValidity => ({ field, error });

function validateAtLeastOnePhoneNumber(
    updatedNumber: FieldName,
    otherNumber: FieldName,
    input: ValidationInput,
): ValidationResult {
    const { value, patientDetails } = input;
    // Neither number given
    if (!value && !patientDetails[otherNumber]) {
        const error = "PatientDetails.PhoneNumber.AtLeastOne";
        return [invalid(updatedNumber, error), invalid(otherNumber, error)];
    }

    const otherValue = patientDetails[otherNumber];
    const otherError = otherValue ? validatePhoneNumber(otherValue) : "";

    // Other number only given
    if (!value) {
        return otherError ? [invalid(otherNumber, otherError)] : [valid(updatedNumber)];
    }
    // Number given
    const error = validatePhoneNumber(value);
    const otherNumberStatus = otherError ? invalid(otherNumber, otherError) : valid(otherNumber);

    return error ? [invalid(updatedNumber, error), otherNumberStatus] : [valid(updatedNumber), otherNumberStatus];
}

const validate = (field: FieldName, input: ValidationInput, validationMethods: ValidatorMethod[]): ValidationResult => {
    for (const validationMethod of validationMethods) {
        const error = validationMethod(input);
        if (error) return [invalid(field, error)];
    }
    return [valid(field)];
};

const validator = (
    field: FieldName,
    valueValidators: ValidatorMethod[],
    controllingField?: FieldName,
): FieldValidator => ({
    field,
    validate: (input: ValidationInput) =>
        controllingField && !input.patientDetails[controllingField]
            ? [valid(field)]
            : validate(field, input, valueValidators),
});

const phoneValidator = (updatedNumber: FieldName, otherNumber: FieldName): FieldValidator => ({
    field: updatedNumber,
    validate: (input: ValidationInput) => validateAtLeastOnePhoneNumber(updatedNumber, otherNumber, input),
});

export const validateField = (
    field: FieldName,
    input: ValidationInput,
    fieldValidators: FieldValidator[],
): ValidationResult => {
    const fieldValidator = fieldValidators.filter(f => f.field === field);
    if (fieldValidator.length !== 1) throw new Error(`Couldn't find single validator match for ${field}`);
    return fieldValidator[0].validate(input);
};

const validateControllingField = (
    fieldName: FieldName,
    input: ValidationInput,
    controlledFieldNames: FieldName[],
): ValidationResult => {
    if (!input.isShowingErrors) {
        return [valid(fieldName), ...controlledFieldNames.map(f => valid(f))];
    }

    const patientDetails = { ...input.patientDetails, [fieldName]: input.value };
    const controlledFieldsValidationResults = controlledFieldNames
        .map(controlledFieldName =>
            validateField(
                controlledFieldName,
                { ...input, value: input.patientDetails[controlledFieldName], patientDetails },
                /* eslint-disable @typescript-eslint/no-use-before-define */
                commonValidators,
                /* eslint-enable @typescript-eslint/no-use-before-define */
            ),
        )
        .flat();

    return [valid(fieldName), ...controlledFieldsValidationResults];
};

const controllingFieldValidator = (fieldName: FieldName, controlledFieldNames: FieldName[]): FieldValidator => ({
    field: fieldName,
    validate: (input: ValidationInput) => validateControllingField(fieldName, input, controlledFieldNames),
});

const commonValidators: FieldValidator[] = [
    validator(FieldName.FirstName, [validateHasValue, validateName]),
    validator(FieldName.LastName, [validateHasValue, validateName]),
    validator(FieldName.Postcode, [validateHasValue, validatePostcode]),
    validator(FieldName.AddressLine1, [validateHasValue]),
    validator(FieldName.Gender, [validateHasValue]),
    validator(FieldName.ReferralReason, [validateHasValue, validateMaxLength, validateFreeText]),
    phoneValidator(FieldName.HomePhoneNumber, FieldName.MobilePhoneNumber),
    phoneValidator(FieldName.MobilePhoneNumber, FieldName.HomePhoneNumber),
    validator(FieldName.DateOfBirth, [validateHasValue, validateDateOfBirth]),
    validator(FieldName.Notes, [validateMaxLength, validateFreeText]),
    controllingFieldValidator(FieldName.IsThirdPartyContact, [FieldName.ContactFirstName, FieldName.ContactLastName]),
    validator(FieldName.ContactFirstName, [validateHasValue, validateName], FieldName.IsThirdPartyContact),
    validator(FieldName.ContactLastName, [validateHasValue, validateName], FieldName.IsThirdPartyContact),
];

export const receptionLedValidators: FieldValidator[] = [
    ...commonValidators,
    validator(FieldName.Email, [validateEmail]),
    validator(FieldName.NhsNumber, [validateNhsNumber]),
];

export const patientLedValidators: FieldValidator[] = [
    ...commonValidators,
    validator(FieldName.Email, [validateHasValue, validateEmail]),
    validator(FieldName.ReEnterEmail, [validateHasValue, validateEmail, validateEmailMatch]),
    validator(FieldName.NhsNumber, [validateNhsNumber]),
];

export const assessmentTemplateValidator: FieldValidator = validator(FieldName.EmailAssessment, [validateHasValue]);
