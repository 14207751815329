const wait = (milliseconds: number): Promise<any> => new Promise(resolve => setTimeout(resolve, milliseconds));
const noRetryCodes = [400, 401, 404, 409];

const fetchWithRetry = async (url: string, options: any, maxRetryCount: number, retryWaitMilliseconds: number) => {
    const tryFetch = async (retries: number): Promise<Response> => {
        const retryAfterWait = (retryCount: number): Promise<any> =>
            wait(retryWaitMilliseconds).then(() => tryFetch(retryCount));

        try {
            const response = await fetch(url, options);
            if (!response.ok && !noRetryCodes.includes(response.status) && retries) {
                return await retryAfterWait(retries - 1);
            }

            return response;
        } catch (err) {
            if (!retries) {
                throw err;
            }
            return retryAfterWait(retries - 1);
        }
    };

    return tryFetch(maxRetryCount);
};

export default fetchWithRetry;
