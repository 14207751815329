import { PatientDetails } from "../types";

export type PatientField = { label: string; error: string };

export type PatientFields = {
    [fieldName: string]: PatientField;
};

export enum FieldName {
    FirstName = "firstName",
    LastName = "lastName",
    MobilePhoneNumber = "mobilePhoneNumber",
    HomePhoneNumber = "homePhoneNumber",
    Email = "email",
    ReEnterEmail = "reEnterEmail",
    Postcode = "postcode",
    AddressLine1 = "addressLine1",
    Gender = "gender",
    NhsNumber = "nhsNumber",
    DateOfBirth = "dateOfBirth",
    ReferralReason = "referralReason",
    Notes = "notes",
    EmailAssessment = "emailAssessment",
    IsThirdPartyContact = "isThirdPartyContact",
    ContactFirstName = "contactFirstName",
    ContactLastName = "contactLastName",
}

export type FieldValidity = {
    field: FieldName;
    error?: string;
};

export type ValidationResult = FieldValidity[];

export type ValidationInput = {
    value: any;
    patientDetails: PatientDetails;
    patientFields: PatientFields;
    isShowingErrors?: boolean;
};

export type ValidatorMethod = (input: ValidationInput) => string;

export type FieldValidator = { field: FieldName; validate: (input: ValidationInput) => ValidationResult };
