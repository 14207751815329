import { checkResponse } from "@emisgroup/patient-connect-common-react";
import { PatientDetails } from "../components/types";

const toDateString = (date: Date): string => {
    if (!date) {
        return null;
    }
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() - offset * 60 * 1000).toISOString().split("T")[0];
};

const createOptionalIdentifier = (type: string, value: string) => {
    return value
        ? [
              {
                  type,
                  identifier: value,
              },
          ]
        : [];
};

const createReferralSubject = (patientDetails: PatientDetails, patientIdentifier: string) => {
    const contactDetails = !patientDetails.isThirdPartyContact
        ? {
              emailAddress: patientDetails.email,
              mobilePhoneNumber: patientDetails.mobilePhoneNumber,
              homePhoneNumber: patientDetails.homePhoneNumber,
          }
        : undefined;

    return {
        demographics: {
            firstName: patientDetails.firstName,
            lastName: patientDetails.lastName,
            dateOfBirth: toDateString(patientDetails.dateOfBirth),
            postcode: patientDetails.postcode,
            gender: patientDetails.gender,
            addressLine1: patientDetails.addressLine1,
            ...contactDetails,
        },
        identifiers: [
            ...createOptionalIdentifier("def.patientplatform.io/NhsNumber", patientDetails.nhsNumber),
            ...createOptionalIdentifier("emis/Patient", patientIdentifier),
        ],
    };
};

const createThirdPartyContact = (patientDetails: PatientDetails) =>
    patientDetails.isThirdPartyContact
        ? {
              firstName: patientDetails.contactFirstName,
              lastName: patientDetails.contactLastName,
              emailAddress: patientDetails.email,
              mobilePhoneNumber: patientDetails.mobilePhoneNumber,
              homePhoneNumber: patientDetails.homePhoneNumber,
          }
        : undefined;

const responseToReferralId = async (response: Response) => {
    await checkResponse(response, "Referral API call failed");
    const referral = await response.json();
    return referral.referralId;
};

export const postReferral = async (
    serviceId: string,
    serviceName: string,
    patientIdentifier: string,
    patientDetails: PatientDetails,
    careProviderId: string,
    careProviderName: string,
    assessmentTemplateResult: any,
    sendAssessmentToSubject: boolean,
    codeId: string,
    referralsUrl: string,
    getRequestInit: (params: any) => Promise<RequestInit>,
) => {
    const referralSubject = createReferralSubject(patientDetails, patientIdentifier);
    const thirdPartyContact = createThirdPartyContact(patientDetails);
    const body = {
        serviceId,
        serviceName,
        redeemedCareProviderId: careProviderId,
        redeemedCareProviderName: careProviderName,
        serviceNotes: patientDetails.notes,
        referralReason: patientDetails.referralReason,
        subject: referralSubject,
        assessmentTemplateResult,
        sendAssessmentToSubject,
        codeId,
        thirdPartyContact,
        verificationToken: patientDetails.humanityCheck,
    };

    let response;
    try {
        response = await fetch(referralsUrl, await getRequestInit({ body }));
    } catch (exception) {
        exception.message = `${exception.message} (post referral)`;
        throw exception;
    }

    return responseToReferralId(response);
};

export enum DeclineType {
    PatientDeclined = "patientdeclined",
    Ineligible = "ineligible",
}

export const postDeclineReferral = async (
    serviceId: string,
    patientIdentifier: string,
    patientDetails: PatientDetails,
    referralReason: string,
    declineType: DeclineType,
    assessmentTemplateResult: any,
    codeId: string,
    referralsUrl: string,
    getRequestInit: (params: any) => Promise<RequestInit>,
) => {
    const declineReferralUrl = `${referralsUrl}/declined`;
    const referralSubject = createReferralSubject(patientDetails, patientIdentifier);
    const body = {
        serviceId,
        referralReason,
        assessmentTemplateResult,
        declineType,
        subject: referralSubject,
        codeId,
    };

    let response;
    try {
        response = await fetch(declineReferralUrl, await getRequestInit({ body }));
    } catch (exception) {
        exception.message = `${exception.message} (decline referral)`;
        throw exception;
    }

    return responseToReferralId(response);
};

export enum ReferralErrorType {
    ConsultationFiling = "consultationFiling",
}

export const postReferralError = async (
    referralId: string,
    referralErrorType: ReferralErrorType,
    referralsUrl: string,
    getRequestInit: (params: any) => Promise<RequestInit>,
) => {
    const referralErrorUrl = `${referralsUrl}/${referralId}/errors`;
    const body = {
        referralErrorType,
    };

    let response;
    try {
        response = await fetch(referralErrorUrl, await getRequestInit({ body }));
    } catch (exception) {
        exception.message = `${exception.message} (post referral error)`;
        throw exception;
    }

    await checkResponse(response, "Failed to post referral error");
};
