import * as React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ServiceContext } from "../components/serviceContext";

export enum ReferralOutcome {
    Referred,
    Declined,
    Ineligible,
}

const DEFAULT_REDIRECT_DELAY_SECS = 10;

export type RedirectsContextType = {
    referralRedirect: (referralOutcome: ReferralOutcome) => void;
    hasCustomRedirect: (referalOutcome: ReferralOutcome) => boolean;
    redirectDelaySeconds: number;
};

const createContext = (navigate: NavigateFunction): RedirectsContextType => {
    const { referredRedirectUrl } = React.useContext(ServiceContext);
    const redirectDelaySeconds = DEFAULT_REDIRECT_DELAY_SECS;

    const getCustomRedirectUrl = (referralOutcome: ReferralOutcome) =>
        referralOutcome === ReferralOutcome.Referred ? referredRedirectUrl ?? undefined : undefined;

    return {
        referralRedirect: (referralOutcome: ReferralOutcome) => {
            const redirectUrl = getCustomRedirectUrl(referralOutcome);
            if (typeof redirectUrl !== "undefined") {
                window.open(redirectUrl, "_blank");
            } else {
                navigate(-1);
            }
        },
        hasCustomRedirect: (referralOutcome: ReferralOutcome) =>
            typeof getCustomRedirectUrl(referralOutcome) !== "undefined",

        redirectDelaySeconds,
    };
};

export const RedirectsContext = React.createContext<RedirectsContextType>({
    referralRedirect: () => {},
    hasCustomRedirect: () => false,
    redirectDelaySeconds: DEFAULT_REDIRECT_DELAY_SECS,
});

type RedirectsProviderProps = {
    children: React.ReactNode;
};

export function RedirectsProvider({ children }: RedirectsProviderProps) {
    const navigate = useNavigate();
    return <RedirectsContext.Provider value={createContext(navigate)}>{children}</RedirectsContext.Provider>;
}
