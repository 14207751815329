import * as React from "react";
import { PatientDetails } from "./types";

const defaultDetails = {} as PatientDetails;

const defaultFunc = () => {
    /* no-op */
};

type PatientDetailsContextType = {
    patientDetails: PatientDetails;
    onChange: (name: string, value: string) => void;
    updatePatientDetails: (newPatientDetails?: PatientDetails) => void;
    clearPatientDetails: () => void;
    isLoading: boolean;
    updateIsLoading: (newState: boolean) => void;
};

export const PatientDetailsContext = React.createContext<PatientDetailsContextType>({
    patientDetails: defaultDetails,
    onChange: defaultFunc,
    updatePatientDetails: defaultFunc,
    clearPatientDetails: defaultFunc,
    isLoading: false,
    updateIsLoading: defaultFunc,
});

type PatientDetailsProviderProps = {
    children: React.ReactNode | React.ReactNode[];
    initialDetails?: PatientDetails;
};

export function PatientDetailsProvider({ children, initialDetails }: PatientDetailsProviderProps) {
    const [patientDetails, setPatientDetails] = React.useState(initialDetails ?? defaultDetails);
    const [isLoading, setIsLoading] = React.useState(false);

    const updatePatientDetails = (newPatientDetails?: PatientDetails) => {
        setPatientDetails(newPatientDetails || patientDetails);
    };
    const onChange = (name: string, value: string): void => {
        setPatientDetails(currentPatientDetails => ({ ...currentPatientDetails, [name]: value }));
    };
    const updateIsLoading = (newState: boolean) => {
        setIsLoading(newState);
    };

    const clearPatientDetails = () => setPatientDetails({} as PatientDetails);

    return (
        <PatientDetailsContext.Provider
            value={{ patientDetails, clearPatientDetails, onChange, updatePatientDetails, isLoading, updateIsLoading }}
        >
            {children}
        </PatientDetailsContext.Provider>
    );
}
