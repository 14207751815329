import React, { useState } from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { EmailAssessmentOption } from "../types";

interface EmailAssessmentDetailsProps {
    id: string;
    error: string;
    isPatientLed: boolean;
}

const EmailAssessmentDetails = ({ id, error, isPatientLed }: EmailAssessmentDetailsProps) => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const options = [
        { text: t(EmailAssessmentOption.Yes), value: EmailAssessmentOption.Yes },
        { text: t(EmailAssessmentOption.No), value: EmailAssessmentOption.No },
    ];

    const handleOptionChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.currentTarget.value);
    }, []);

    const userType = isPatientLed ? "Patient" : "Practice";

    return (
        <div className={`nhsuk-form-group${error ? " nhsuk-form-group--error" : ""}`}>
            <fieldset className="nhsuk-fieldset">
                <label className="nhsuk-label" htmlFor={id}>
                    {t(`PatientDetails.ReceiveAssessmentInEmail${userType}`)}
                </label>

                <div id={id} className="nhsuk-radios">
                    {options.map(o => (
                        <div key={o.value} className="nhsuk-radios__item">
                            <input
                                data-testid={`${id}-option-${o.value}`}
                                className="nhsuk-radios__input"
                                id={`${id}-option-${o.value}`}
                                type="radio"
                                value={o.value}
                                checked={selectedOption === o.value}
                                onChange={handleOptionChange}
                            />
                            <label className="nhsuk-label nhsuk-radios__label" htmlFor={`${id}-option-${o.value}`}>
                                {o.text}
                            </label>
                        </div>
                    ))}
                </div>

                {selectedOption !== null && (
                    <div className="nhsuk-inset-text nhsuk-u-margin-top-3 nhsuk-u-margin-bottom-1">
                        <span className="nhsuk-u-visually-hidden">Information: </span>
                        <p>
                            {t(
                                `PatientDetails.${
                                    selectedOption == EmailAssessmentOption.No ? "No" : ""
                                }AssessmentInEmail${userType}`,
                            )}
                        </p>
                    </div>
                )}

                <span className="nhsuk-error-message">
                    <span className="nhsuk-u-visually-hidden">Error:</span> {error}
                </span>
            </fieldset>
        </div>
    );
};

export default EmailAssessmentDetails;
