import { SessionItems } from "@emisgroup/application-session-management";
import { useSessionContext } from "@emisgroup/application-session-react";
import * as React from "react";
import { NavigationType, useNavigationType } from "react-router-dom";
import { EnvironmentContext } from "../environmentContext";
import { endReferralSession, getReferralSessionPatient, startReferralSession } from "./referralSessionUtils";

const usePatientChangeMonitor = () => {
    const { isRunningInApplicationFrame } = React.useContext(EnvironmentContext);
    const navigationType = useNavigationType();
    const sessionContext = useSessionContext();
    const currentPatient = sessionContext.GetItem(SessionItems.Patient) as string;
    const [patientChanged, setPatientChanged] = React.useState(false);

    function clearPatientChanged() {
        setPatientChanged(false);
    }

    React.useEffect(() => {
        const referralPatient = getReferralSessionPatient();
        if (referralPatient) {
            if (referralPatient !== currentPatient) {
                setPatientChanged(true);
            }
        } else if (currentPatient) {
            startReferralSession(currentPatient);
        }

        return () => {
            if (isRunningInApplicationFrame && navigationType === NavigationType.Pop) {
                endReferralSession();
            }
        };
    }, []);
    return { patientChanged, currentPatient, clearPatientChanged };
};

export default usePatientChangeMonitor;
